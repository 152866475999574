import { ReactComponent as SortUpIcon } from '../../assets/svg/sort_up.svg';
import { ReactComponent as SortDownIcon } from '../../assets/svg/sort_down.svg';
import style from './TableHead.module.scss';

const TableHead = ({ headerGroups, onSort }) => (
    <thead className={style.tableHead}>
        {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                    <th
                        className="table-head"
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                        <div
                            onClick={() => {
                                column.canSort && onSort(column);
                            }}
                        >
                            <span>{column.render('Header')}</span>
                            {column.canSort && (
                                <span className={style.sortIcons}>
                                    <SortUpIcon
                                        className={
                                            column.isSortedDesc === false ? style.activeSort : ''
                                        }
                                    />
                                    <SortDownIcon
                                        className={
                                            column.isSortedDesc === true ? style.activeSort : ''
                                        }
                                    />
                                </span>
                            )}
                        </div>
                    </th>
                ))}
            </tr>
        ))}
    </thead>
);

export default TableHead;

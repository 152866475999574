import { NavLink } from 'react-router-dom';
import { FiChevronRight } from 'react-icons/fi';
import style from './NavTabs.module.scss';
import { makeId } from '../../utils';

const NavTabs = ({ children, path }) => (
    <article className="menuBar">
        <div>
            <ul className={style.navTabs}>
                {path.map(({ text, to }, idx) => (
                    <li key={makeId()}>
                        {path[idx + 1] ? <NavLink to={to}>{text}</NavLink> : <span>{text}</span>}
                        {path.length - 1 !== idx ? <FiChevronRight /> : null}
                    </li>
                ))}
            </ul>
        </div>

        {children}
    </article>
);

export default NavTabs;

const getTextFromHtmlString = (stringWithTags = '') => {
    if (typeof stringWithTags !== 'string') return '';
    return stringWithTags
        .replace(/<[A-Za-z|-]+>/g, '')
        .replace(/\n/g, '')
        .replace(/<p>/g, '')
        .replace(/<\/p>/g, '')
        .replace(/&nbsp;/g, '')
        .trim();
};

export default getTextFromHtmlString;

/* eslint-disable no-shadow,eqeqeq,css-modules/no-unused-class */
import React, { useRef, useState } from 'react';
import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im';
import { AiOutlineClose } from 'react-icons/ai';
import { IoMdArrowDropdown } from 'react-icons/io';
import clsx from 'clsx';
import { useClickOutside } from '../../hooks';
import inputStyle from '../InputField/InputField.module.scss';
import InputErrorMessage from '../InputErrorMessage/InputErrorMessage';
import { Spinner } from '..';
import style from './Select.module.scss';

const Select = React.forwardRef(
    (
        {
            onChange: propOnChange = () => {},
            placeholder,
            name,
            className = `select-${name}`,
            onSearchChange = () => {},
            options = [],
            value = [],
            testingId = '',
            defaultOptionLabel = 'All',
            onClear = () => {},
            tags = false,
            multiSelect = false,
            defaultOption = false,
            search = false,
            optionsSortFn,
            error,
            label,
            disabled,
            optionsLoading,
            ...props
        },
        ref,
    ) => {
        multiSelect = multiSelect || tags;
        search = search || tags;

        if (!Array.isArray(value) && value) {
            value = [value];
        }

        if (!Array.isArray(value)) value = [];

        // options = uniqBy(options, 'value');
        options = [...new Map(options.map((item) => [item.value, item])).values()];

        if (optionsSortFn) options = options.sort(optionsSortFn);

        const [showOptions, setShowOptions] = useState(false);
        const [inputValue, setInputValue] = useState('');
        const wrapperRef = useRef(null);
        const searchRef = useRef(null);

        useClickOutside(wrapperRef, () => {
            setShowOptions(false);
            setInputValue('');
            onSearchChange('');
        });

        const selectedLength = value.length;
        const dataTestingId = name ? `multiSelect-${name}` : testingId;

        const getCheckboxIcon = (checked) => {
            if (multiSelect) return checked ? <ImCheckboxChecked /> : <ImCheckboxUnchecked />;
            return null;
        };

        const inputClasses = showOptions ? style.inputDropdown : 'input-field';

        const inputDivClasses = clsx(
            style.input,
            inputClasses,
            !multiSelect && !search && style['input-caret-hidden'],
        );

        const wrapperDivClasses = clsx(
            'multi-select',
            className,
            inputStyle.inputField,
            disabled && style['multiSelect-disabled'],
            showOptions && inputStyle.inputFieldDropdownActive,
        );

        const selectedOptionsPreview = value
            .map((val) => {
                const selectedOption = options.find((opt) => String(opt.value) === String(val));
                return selectedOption?.text || selectedOption?.label;
            })
            .join(', ');

        const onChange = (value) => {
            if (!multiSelect) propOnChange(name, value[0]);
            else propOnChange(name, value);
        };

        const handleSearchChange = (e) => {
            const {
                target: { value: inputValue },
            } = e;
            onSearchChange(inputValue);
            setInputValue(inputValue);
        };

        const handleEnter = (e) => {
            if (e.key === 'Enter') {
                const filteredOptions = options.filter(({ label, text }) =>
                    (label || text).toLowerCase().includes(inputValue.toLowerCase()),
                );
                handleCheckboxChange(filteredOptions[0]);
                !multiSelect && setShowOptions(false);
            }
        };

        const handleCheckboxChange = (option) => {
            setInputValue('');
            onSearchChange('');
            // const newSelected = xor(value, [option.value]);
            const newSelected = value.reduce((filtered, val) => {
                if (val !== option.value) {
                    filtered.push(val);
                }
                return filtered;
            }, []);

            onChange(multiSelect ? newSelected : [option.value]);
        };

        const handleClear = () => {
            onChange([]);
            onClear();
        };

        const handlePopOption = (e) => {
            if (e.key === 'Backspace' && multiSelect && value.length > 0)
                onChange(value.slice(0, -1));
        };

        const mappedOptions = options?.flatMap((option) => {
            const label = option.text || option.label;
            const labelTestingId = `${dataTestingId}-value-${option.text}`;
            const isSelected = !!value.find((val) => String(val) === String(option.value));

            const handleChange = (e) => {
                !multiSelect && setShowOptions(false);
                e.preventDefault();
                e.stopPropagation();
                handleCheckboxChange(option);
            };

            if (!label || !option.value) return [];

            if (label.toLowerCase().includes(inputValue.toLowerCase()))
                return (
                    <div
                        data-testing-id={labelTestingId}
                        className={style.option}
                        key={option.value}
                        onClick={handleChange}
                    >
                        {getCheckboxIcon(isSelected)}
                        <input defaultChecked={isSelected} type="checkbox" />
                        {label}
                    </div>
                );
            return [];
        });

        const defaultOptionElement = defaultOption ? (
            <div className={`${style.option} ${className}`}>
                {getCheckboxIcon(selectedLength === 0)}
                <input
                    onChange={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                    type="checkbox"
                    onClick={handleClear}
                    checked={selectedLength === 0}
                    ref={ref}
                />
                {defaultOptionLabel}
            </div>
        ) : null;

        const mappedTags = value.map((option, i) => (
            <span key={option}>
                {options.find((opt) => opt.value === value[i])?.text}
                <span onClick={() => handleCheckboxChange({ value: option })}>
                    <AiOutlineClose />
                </span>
            </span>
        ));

        return (
            <div className={wrapperDivClasses} title={selectedOptionsPreview}>
                {!!label && (
                    <span className="inputFieldLabel">
                        {label}
                        {props.required && <span>*</span>}
                    </span>
                )}
                <div className={style.inputWrapper}>
                    <div className={inputDivClasses}>
                        <input
                            ref={ref}
                            data-testing-id={dataTestingId}
                            placeholder={selectedOptionsPreview || placeholder}
                            value={selectedOptionsPreview || ''}
                            readOnly
                            type="text"
                            onKeyDown={handlePopOption}
                            onFocus={() => {
                                setShowOptions(true);
                            }}
                        />
                        <IoMdArrowDropdown onClick={() => setShowOptions(true)} />
                    </div>
                    {!!showOptions && (
                        <div className={style.optionsContainer}>
                            <div ref={wrapperRef}>
                                {search && (
                                    <input
                                        ref={searchRef}
                                        value={inputValue}
                                        placeholder="Search"
                                        className={style.search}
                                        onKeyDown={handleEnter}
                                        onChange={handleSearchChange}
                                    />
                                )}
                                {optionsLoading ? (
                                    <Spinner />
                                ) : (
                                    <>
                                        {defaultOption && defaultOptionElement}
                                        {mappedOptions}
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                    <InputErrorMessage message={error?.message} />
                    {!!tags && (
                        <div className={`${style.tags} ${disabled ? style.disabledTags : ''}`}>
                            {mappedTags}
                        </div>
                    )}
                </div>
            </div>
        );
    },
);

export default Select;

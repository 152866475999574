import React from 'react';
import { ImCheckboxUnchecked, ImCheckboxChecked } from 'react-icons/im';
import style from './Checkbox.module.scss';

const Checkbox = ({ label, value, checked, onChange, name, disabled }) => (
    <div
        className={`${style.checkbox} checkbox ${disabled ? style.disabled : ''}`}
        onClick={() => !disabled && onChange(name, value)}
    >
        <span>{label}</span>
        {checked ? <ImCheckboxChecked /> : <ImCheckboxUnchecked />}
    </div>
);

export default Checkbox;

import style from './Radio.module.scss';

const Radio = ({ testingId = '', ...props }) => {
    const dataTestingId = props.name ? `radio-${props.name}-value-${props.value}` : testingId;
    return (
        <label data-testing-id={dataTestingId} className={style.radio}>
            <input {...props} type="radio" />
            <span>{props.label}</span>
        </label>
    );
};

export default Radio;

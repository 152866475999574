import React from 'react';
import style from './InputErrorMessage.module.scss';

const InputErrorMessage = ({ message, styles }) =>
    message ? (
        <span className={`${style.inputErrorMessage} input-error-message`} style={{ ...styles }}>
            {message}
        </span>
    ) : null;

export default InputErrorMessage;

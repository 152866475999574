/* eslint-disable css-modules/no-unused-class */
import React from 'react';
import clsx from 'clsx';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { FiSearch as SearchIcon } from 'react-icons/fi';
import { InputErrorMessage } from '..';
import style from './InputField.module.scss';

const InputField = React.forwardRef(
    (
        {
            label = '',
            labelSize = '',
            htmlTag = 'input',
            isSearch = false,
            type = 'text',
            testingId = '',
            className = '',
            error,
            errorStyles,
            value,
            disabled,
            isDirty,
            isTouched,
            inputWrapperClass = '',
            textAreaWrapperClass = '',
            ...props
        },
        ref,
    ) => {
        const T = { input: 'input', textarea: 'textarea' }[htmlTag];
        const dataTestingId = props.name ? `input-${props.name}` : testingId;

        return (
            <div
                className={clsx(style.inputField, disabled && style.inputFieldDisabled, className)}
            >
                {label && (
                    <span
                        className={`inputFieldLabel ${
                            labelSize === 'small' ? 'inputFieldLabelSmall' : ''
                        }`}
                    >
                        <span>{label}</span>
                        {props.required && <span>*</span>}
                    </span>
                )}
                <div
                    className={clsx(
                        style.inputInnerWrapper,
                        T === 'textarea' && textAreaWrapperClass,
                        inputWrapperClass,
                    )}
                >
                    <T
                        data-testing-id={dataTestingId}
                        ref={ref}
                        type={type}
                        {...props}
                        value={value ?? ''}
                        {...(disabled && { disabled })}
                    />
                    <span>{isSearch ? <SearchIcon color="#E5E5E5" size="18" /> : null}</span>
                    <InputErrorMessage message={error?.message} styles={{ ...errorStyles }} />
                </div>
            </div>
        );
    },
);

export default InputField;

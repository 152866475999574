import React from 'react';
import style from './Switch.module.scss';
import InputErrorMessage from '../InputErrorMessage/InputErrorMessage';

const Switch = React.forwardRef(
    (
        {
            label,
            value,
            name,
            onChange,
            labelWidthClass = 'mr-4',
            className = '',
            testingId = '',
            error,
            disabled = false,
        },

        ref,
    ) => {
        const dataTestingId = name ? `switch-${name}` : testingId;

        return (
            <div className={`switch ${className} ${style.switch}`}>
                <label className={`${labelWidthClass} inputFieldLabel`}>{label}</label>
                <span>
                    <input
                        ref={ref}
                        data-testing-id={dataTestingId}
                        name={name}
                        onClick={onChange}
                        type="checkbox"
                        checked={!!value}
                        readOnly
                        disabled={disabled}
                    />
                    <span className={`${style.slider} ${style.round}`} />
                </span>
                <InputErrorMessage message={error?.message} />
            </div>
        );
    },
);

export default Switch;

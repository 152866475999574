import { useEffect, useState } from 'react';
import { useSearchParams, useOutletContext } from 'react-router-dom';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import Qs from 'qs';
import { archivedProjectsColumns } from './tableConfig';
import { getProjects, deleteProject, saveProject } from '../../api';
import { ActionCell, NavTabs, Table, Search } from '../../components';
import { archivedProjectsModals } from '../constants';
import { sortData, filterData } from '../../utils';
import style from '../ProjectOverviewPage/ProjectOverviewPage.module.scss';

const ProjectArchivesPage = () => {
    const [urlParams, setUrlParams] = useSearchParams();
    const [{ setActiveModal }] = useOutletContext();
    const [data, setData] = useState([]);

    const params = Qs.parse(urlParams.toString(), { arrayFormat: 'indices' });
    const { order = 'desc', sort = 'id' } = params;

    const query = useQuery(['archivedProjects', true], getProjects, {
        retry: 1,
        onError: () => {
            toast('An error has occurred, projects are not loaded.');
        },
    });

    const handleSort = (column) => {
        const newParams = { ...params };
        const { id } = column;
        const newSort = id;
        const prevOrder = urlParams.get('sort') === id && urlParams.get('order');

        let newOrder;
        if (!prevOrder) newOrder = 'asc';
        else if (prevOrder === 'asc') newOrder = 'desc';
        else if (prevOrder === 'desc') newOrder = '';

        newParams.order = newOrder;
        newParams.sort = newSort;
        if (!newOrder) {
            delete newParams.order;
            delete newParams.sort;
        }

        setUrlParams(Qs.stringify(newParams, { arrayFormat: 'indices' }));
    };

    useEffect(() => {
        if (!query.data) return;

        const dataToFilter = query.data.slice();

        const filteredData = filterData(dataToFilter, params.search);

        const sortedData = sortData(filteredData, sort, order);

        setData(sortedData);
    }, [query.data, params.search, order, sort]);

    const handleRestore = async (id) => {
        const project = query.data.find((proj) => proj.id === id);
        project.archived = false;
        await saveProject(project);
        query.refetch();
        toast(`Project "${project.name}" restored.`);
    };

    const handleRestoreModal = (id) => {
        const project = query.data.find((proj) => proj.id === id);
        const modal = { ...archivedProjectsModals.restore };
        modal.text = `${modal.text} "${project.name}"?`;
        modal.handle = () => handleRestore(id);
        setActiveModal(modal);
    };

    const handleDelete = async (project) => {
        await deleteProject(project.id);
        query.refetch();
        toast(`Project "${project.name}" deleted.`);
    };

    const handleDeleteModal = (id) => {
        const project = query.data.find((proj) => proj.id === id);
        const modal = { ...archivedProjectsModals.delete };
        modal.text = `${modal.text} "${project.name}"?`;
        modal.handle = () => handleDelete(project);
        setActiveModal(modal);
    };

    const columns = archivedProjectsColumns.map((col) => {
        if (col.id === 'actions')
            return {
                ...col,
                Cell: ({ row }) => (
                    <ActionCell
                        itemId={row.original.id}
                        onRestore={handleRestoreModal}
                        onDelete={handleDeleteModal}
                    />
                ),
            };
        if (col.accessor === 'createdAt') {
            return {
                ...col,
                Cell: ({ row }) => (
                    <span>{new Date(row.original[col.accessor]).toLocaleDateString()}</span>
                ),
            };
        }
        return col;
    });

    const links = [
        { text: 'Project overview', to: '/projects' },
        {
            text: 'Archived projects',
            to: '/projects/archived',
        },
    ];

    return (
        <section className={style.projectOverview}>
            <NavTabs path={links}>
                <Search />
            </NavTabs>
            <div className={style.projectOverviewTable}>
                <Table columns={columns} data={data} onSort={handleSort} />
            </div>
        </section>
    );
};

export default ProjectArchivesPage;

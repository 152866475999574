import { useState, useRef } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { CgLogOut, CgProfile } from 'react-icons/cg';
import useAppState from '../../state/useAppState';
import { useDiscard } from '../../hooks';
import { logout } from '../../api';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import style from './Header.module.scss';

const navItems = [
    { label: 'Projects', to: 'projects' },
    { label: 'Platform Page', to: 'platform' },
];

const Header = () => {
    const [fallbackAvatar, setFallbackAvatar] = useState(false);
    const [isDropdownActive, setIsDropdownActive] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { user } = useAppState();
    const ref = useRef(null);
    useDiscard(ref, () => setIsDropdownActive(false));

    const handleHomeNavigation = () => {
        location.pathname !== '/projects' && navigate('./', { replace: true });
    };

    const handleLogout = () => {
        logout().then(() => window.location.replace('https://eyesee-hub.com/'));
    };

    const toggleDropdown = () => setIsDropdownActive((active) => !active);

    return (
        <header className={style.header}>
            <div>
                <Logo onClick={handleHomeNavigation} />
                <nav>
                    {navItems.map((item) => (
                        <NavLink key={item.to} to={item.to}>
                            {item.label}
                        </NavLink>
                    ))}
                </nav>
            </div>
            <div
                ref={ref}
                className={style.userDropdown}
                onClick={toggleDropdown}
                data-testing-id="user-dropdown"
            >
                <span>
                    {user.firstName} {user.lastName}
                </span>
                {!fallbackAvatar ? (
                    <img onError={() => setFallbackAvatar(true)} src={user.img} alt={user.name} />
                ) : (
                    <CgProfile />
                )}
                {isDropdownActive && (
                    <menu>
                        <button
                            data-testing-id="button-logout"
                            type="button"
                            onClick={handleLogout}
                        >
                            <CgLogOut /> Log out
                        </button>
                    </menu>
                )}
            </div>
        </header>
    );
};

export default Header;

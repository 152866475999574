import axios from 'axios';
import routes from './routes';

const INSTANCE = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

INSTANCE.interceptors.request.use(
    (req) => {
        const token = localStorage.getItem('mobileAdminToken');
        req.headers.common.Authorization = `Bearer ${token}`;

        return req;
    },
    (err) => Promise.reject(err),
);

INSTANCE.interceptors.response.use(
    (res) => res,
    (err) => {
        if (err.response?.status === 401 && err.response?.config.url !== routes.user) {
            return window.location.replace('https://eyesee-hub.com/');
        }
        return Promise.reject(err);
    },
);

const request = (options) => INSTANCE(options).then(({ data }) => data);

export const get = (url, options) => request({ url, method: 'get', ...options });

export const post = (url, data, options) => request({ url, method: 'post', data, ...options });

export const put = (url, data, options) => request({ url, method: 'put', data, ...options });

export const httpDelete = (url, options) => request({ url, method: 'delete', ...options });

export default INSTANCE;

import { useState } from 'react';
import { Select, NavTabs } from '../../components';
import useAppState from '../../state/useAppState';
import { languageOptions } from '../../utils';
import style from './PlatformPage.module.scss';

const { REACT_APP_DOWNLOAD_URL } = process.env;

const PlatformPage = () => {
    const [language, setLanguage] = useState('en');
    const { user } = useAppState();

    const handleChangeLanguage = (name, value) => {
        setLanguage(value);
    };

    const availableLanguageOptions = user.languages.map((langCode) => ({
        text: languageOptions.find((lang) => lang.code === langCode)?.native,
        value: langCode,
    }));

    const links = [{ text: 'Platform page preview', to: '/platform' }];

    return (
        <section className={`page ${style.platform}`}>
            <NavTabs path={links}>
                <div className="menuBarButtons">
                    <Select
                        label="Select language"
                        options={availableLanguageOptions}
                        name="selectedLanguage"
                        value={language}
                        onChange={handleChangeLanguage}
                    />
                </div>
            </NavTabs>
            <article>
                <iframe
                    id="slide1"
                    title="Slide 1"
                    src={`${REACT_APP_DOWNLOAD_URL}/?lang=${language}&slide=1`}
                />
                <iframe
                    id="slide2"
                    title="Slide 2"
                    src={`${REACT_APP_DOWNLOAD_URL}/?lang=${language}&slide=2`}
                />
                <iframe
                    id="slide3"
                    title="Slide 3"
                    src={`${REACT_APP_DOWNLOAD_URL}/?lang=${language}&slide=3`}
                />
            </article>
        </section>
    );
};

export default PlatformPage;

const routes = {
    user: '/user',
    logout: '/logout',
    projects: '/projects',
    users: '/users',
    projectsExport: '/projects-export',
    languages: {
        cell: '/languages/cell',
        download: '/languages/download',
    },
    cell: '/cell',
};

export default routes;

import React, { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClient, QueryClientProvider } from 'react-query';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Spinner, Toaster, PreviewLinkProvider } from './components';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            suspense: true,
            refetchOnWindowFocus: false,
        },
    },
});

const AppWrappers = ({ children }) => (
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools />
            <Router>
                <Suspense fallback={<Spinner fullscreen />}>
                    <PreviewLinkProvider>
                        <DndProvider backend={HTML5Backend}>
                            <Toaster />
                            {children}
                        </DndProvider>
                    </PreviewLinkProvider>
                </Suspense>
            </Router>
        </QueryClientProvider>
    </React.StrictMode>
);

export default AppWrappers;

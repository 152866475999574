import { useEffect, useMemo, useState } from 'react';
import { useParams, useOutletContext } from 'react-router-dom';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { getCellWebExport } from '../../api';
import { NavTabs, Table } from '../../components';
import { cellWebExportColumns } from './tableConfig';
import style from './CellWebExportPage.module.scss';

const CellWebExportPage = () => {
    const { projectId, cellId } = useParams();
    const [{ project }] = useOutletContext();
    const [tableData, setTableData] = useState([]);

    const cell = useMemo(
        () => project?.cells?.find((innerCell) => String(innerCell.order) === cellId),
        [project.cells, cellId],
    );

    const query = useQuery(['cellWebExport', cell?.id], getCellWebExport, {
        enabled: Boolean(cell),
        retry: 1,
        onError: () => {
            toast('An error has occurred.');
        },
    });

    const links = [
        { text: 'Project overview', to: '/projects' },
        {
            text: projectId === 'new' ? 'Create new project' : `Project ${project.name}`,
            to: `/projects/${projectId}`,
        },
        {
            text: `Web export - ${cell?.name}`,
            to: `/projects/${projectId}/${cellId}/webexport`,
        },
    ];

    useEffect(() => {
        if (!query.data) return;
        const { rows } = query.data;
        setTableData(rows);
    }, [query.data]);

    if (!query.data) return null;

    const columns = cellWebExportColumns.map((col) => ({ ...col, disableSortBy: true }));

    return (
        <section className={style.cellWebExport}>
            <NavTabs path={links} />
            <article className={style.cellWebExportTable}>
                <div>
                    <h2>Web export - {cell?.name}</h2>
                </div>
                {tableData.length && (
                    <Table columns={columns} data={tableData} isPaginated={false} />
                )}
            </article>
        </section>
    );
};

export default CellWebExportPage;

import { useEffect, useMemo, useState } from 'react';
import { useParams, useOutletContext } from 'react-router-dom';
import { useQuery } from 'react-query';
import { FiBook as PublishIcon } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { getCellStats, getFile } from '../../api';
import { NavTabs, Table } from '../../components';
import { cellStatsColumns, horizontalColumns } from './tableConfig';
import style from './CellStatsPage.module.scss';

const CellStatsPage = () => {
    const { projectId, cellId } = useParams();
    const [{ project }] = useOutletContext();
    const [tableData, setTableData] = useState([]);

    const cell = useMemo(
        () => project?.cells?.find((innerCell) => String(innerCell.order) === cellId),
        [project.cells, cellId],
    );

    const query = useQuery(['cellStats', cell?.id], getCellStats, {
        enabled: Boolean(cell),
        retry: 1,
        onError: () => {
            toast('An error has occurred.');
        },
    });

    const handleExport = () => {
        getFile(`/cell/${cell.id}/stats/export`).catch((err) =>
            toast.error(err?.response?.data?.message || err?.message),
        );
    };

    const links = [
        { text: 'Project overview', to: '/projects' },
        {
            text: projectId === 'new' ? 'Create new project' : `Project ${project.name}`,
            to: `/projects/${projectId}`,
        },
        {
            text: `Statistics - ${cell?.name}`,
            to: `/projects/${projectId}/${cellId}/stats`,
        },
    ];

    useEffect(() => {
        if (!query.data) return;
        const { stats } = query.data;
        setTableData(stats.map((row, idx) => ({ ...row, emptyCol: horizontalColumns[idx] })));
    }, [query.data]);

    if (!query.data) return null;

    const fullColumns = [
        ...cellStatsColumns.slice(0, 5),
        ...query.data.columnOrder
            .slice(4, -2)
            .map((id) => ({ accessor: id, Header: `Task ${id.slice(11)} started` })),
        ...cellStatsColumns.slice(-2),
    ].map((col) => ({ ...col, disableSortBy: true }));

    const columns = fullColumns.map((col) => {
        if (col.accessor === 'emptyCol') {
            return { ...col, Cell: ({ row }) => <b>{row.original.emptyCol}</b> };
        }
        return col;
    });

    return (
        <section className={style.cellStats}>
            <NavTabs path={links}>
                <div className="menuBarButtons">
                    <button className="redBtn" type="button" onClick={handleExport}>
                        <PublishIcon />
                        Export
                    </button>
                </div>
            </NavTabs>
            <article className={style.cellStatsTable}>
                <div>
                    <h2>Statistics - {cell?.name}</h2>
                </div>
                {tableData.length && (
                    <Table columns={columns} data={tableData} isPaginated={false} />
                )}
            </article>
        </section>
    );
};

export default CellStatsPage;

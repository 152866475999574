import { FiLink2 as LinkIcon } from 'react-icons/fi';
import { IconButton, PreviewLink, usePreviewLink } from '..';

const LinkCell = ({ link }) => {
    const { openPreviewLink, previewLink } = usePreviewLink();
    return (
        <div className="linkCell">
            <span title="Copy Link">
                <IconButton onClick={(e) => openPreviewLink(e, link)}>
                    <LinkIcon />
                </IconButton>
                {previewLink && <PreviewLink {...previewLink} />}
            </span>
        </div>
    );
};

export default LinkCell;

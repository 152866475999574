import style from './IconButton.module.scss';

const IconButton = ({ children, as = 'button', ...props }) => {
    const T = as;
    return (
        <T className={style.iconButton} type="button" {...props}>
            {children}
        </T>
    );
};

export default IconButton;

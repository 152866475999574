import { useSearchParams } from 'react-router-dom';
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { useRandomId } from '../../hooks';
import TableHead from './TableHead';
import Pagination from './Pagination';

const Table = ({ title, columns, data = [], isPaginated = true, reorderData, onSort }) => {
    const tableId = useRandomId();
    const [urlParams, setUrlParams] = useSearchParams();
    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        headerGroups,
        state,
        rows,
        page,
        setPageSize,
        gotoPage,
        previousPage,
        nextPage,
        canPreviousPage,
        canNextPage,
        pageCount,
    } = useTable(
        {
            columns,
            data,
            reorderData,
            manualSortBy: true,
            initialState: {
                sortBy: [
                    {
                        id: urlParams.get('sort'),
                        desc: urlParams.get('order') === 'desc',
                    },
                ],
            },
        },
        useGlobalFilter,
        useSortBy,
        usePagination,
    );

    const paginationProps = {
        state,
        setPageSize,
        gotoPage,
        previousPage,
        nextPage,
        canPreviousPage,
        canNextPage,
        pageCount,
    };

    const rowsToMap = isPaginated ? page : rows;

    return (
        <>
            <table {...getTableProps()} aria-labelledby={title ? tableId : undefined}>
                <TableHead headerGroups={headerGroups} onSort={onSort} />
                <tbody {...getTableBodyProps()}>
                    {rowsToMap.map((row) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell, idx) => (
                                    <td
                                        {...cell.getCellProps()}
                                        className={`${!row.cells[idx + 1] ? 'bg-white' : ''}`}
                                    >
                                        <div>{cell.render('Cell')}</div>
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {isPaginated && <Pagination {...paginationProps} />}
        </>
    );
};

export default Table;

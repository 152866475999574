const encodeGetParams = (search) =>
    search
        ? Object.entries(
              JSON.parse(`{"${search.replace(/&/g, '","').replace(/=/g, '":"')}"}`, (key, value) =>
                  key === '' ? value : decodeURIComponent(value),
              ),
          )
              .map((kv) => kv.map(encodeURIComponent).join('='))
              .join('&')
        : '';

export default encodeGetParams;

import * as React from 'react';
import { ToastContainer } from 'react-toastify';
import style from './Toaster.module.scss';

function Toaster() {
    return (
        <ToastContainer
            toastClassName={style.eyeseeToast}
            bodyClassName={style.eyeseeToastBody}
            className={style.eyeseeToastContainer}
            theme="dark" //! change this to light for light mode
            autoClose={1000}
            newestOnTop={false}
            closeOnClick={false}
            closeButton={false}
            pauseOnFocusLoss
            draggable={false}
            pauseOnHover
            limit={1}
            position="bottom-center"
            hideProgressBar
        />
    );
}

export default Toaster;

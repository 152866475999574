import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { Header, Spinner, usePreviewLink } from './components';
import {
    ErrorPage,
    Project,
    ProjectOverviewPage,
    ProjectPage,
    ProjectArchivesPage,
    CellPage,
    CellStatsPage,
    CellWebExportPage,
    PlatformPage,
} from './pages';
import { useAuthentication } from './hooks';
import useAppState from './state/useAppState';
import style from './App.module.scss';

function App() {
    useAuthentication();
    const { user } = useAppState();
    const navigate = useNavigate();
    const { hidePreviewLink } = usePreviewLink();

    if (!user) return <Spinner fullscreen />;

    return (
        <main className={style.main}>
            <Header />
            <ErrorBoundary FallbackComponent={ErrorPage} onReset={() => navigate('/')}>
                <Suspense fallback={<LoadingScreen />}>
                    <div className={style.layout} onClick={hidePreviewLink}>
                        <Routes>
                            <Route path="/*" element={<RedirectHome />} />
                            <Route path="/platform" element={<PlatformPage />} />
                            <Route path="/projects" element={<Project />}>
                                <Route index element={<ProjectOverviewPage />} />
                                <Route path="archives" element={<ProjectArchivesPage />} />
                                <Route path=":projectId" element={<ProjectPage />} />
                                <Route path=":projectId/:cellId" element={<CellPage />} />
                                <Route
                                    path=":projectId/:cellId/stats"
                                    element={<CellStatsPage />}
                                />
                                <Route
                                    path=":projectId/:cellId/webexport"
                                    element={<CellWebExportPage />}
                                />
                            </Route>
                        </Routes>
                    </div>
                </Suspense>
            </ErrorBoundary>
        </main>
    );
}

const LoadingScreen = () => (
    <main className={style.loadingScreen}>
        <Spinner />
    </main>
);

const RedirectHome = () => (
    <Navigate
        from="/"
        to={{
            pathname: '/projects',
            search: window.location.search,
        }}
    />
);

export default App;

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useBlocker } from '.';

export const useNavigationBlocker = (blockCallback = () => {}) => {
    const [blockNavigation, setBlockNavigation] = useState(false);
    const [blockAgain, setBlockAgain] = useState(false);
    const [navigateAway, setNavigateAway] = useState(false);
    const [destination, setDestination] = useState(false);
    const navigate = useNavigate();

    useBlocker((action) => {
        setDestination(action);
        blockCallback(action);
    }, blockNavigation);

    useEffect(() => {
        if (navigateAway && !blockNavigation) {
            navigate(destination.location.pathname, { replace: true });
            setNavigateAway(false);
            setBlockNavigation(!!blockAgain);
        }
    }, [navigateAway, blockNavigation, blockAgain, navigate, destination]);

    const continueNavigation = (onceOnly = false) => {
        setBlockNavigation(false);
        setBlockAgain(onceOnly);
        setNavigateAway(true);
    };

    return { blockNavigation, setBlockNavigation, continueNavigation };
};

export const cellStatsColumns = [
    { accessor: 'emptyCol', Header: '' },
    { accessor: 'platformPage', Header: 'Platform page' },
    { accessor: 'storeRedirection', Header: 'Google playstore redirection' },
    { accessor: 'installed', Header: 'Installed application' },
    { accessor: 'testStarted', Header: 'Started test' },
    { accessor: 'testCompleted', Header: 'Test completed' },
    { accessor: 'uploadedData', Header: 'Uploaded data' },
];

export const horizontalColumns = [
    'No. of respondents',
    '% of respondents',
    '% between steps',
    '% from test started',
];

import { forwardRef } from 'react';
import style from './Button.module.scss';

const Button = forwardRef(
    (
        {
            children,
            onClick,
            backgroundColor = 'var(--brand)',
            color = 'white',
            borderColor = 'var(--brand)',
            ...props
        },
        ref,
    ) => (
        <button
            ref={ref}
            className={style.button}
            type="button"
            style={{ backgroundColor, color, borderColor }}
            onClick={onClick}
            {...props}
        >
            {children}
        </button>
    ),
);

export default Button;

import { FaChevronUp } from 'react-icons/fa';
import { InputField, EditorInputField } from '../../components';
import { getDataId } from '../../utils';
import { configurationSections as sections, standardFields } from '../constants';

const Configuration = ({ errors, language, languagePack, onToggleDetails, onInputChange }) => (
    <section className="expandableSection">
        {sections.map(({ names, label }) => (
            <details
                key={label}
                onToggle={onToggleDetails}
                data-id={getDataId(names[0] || names[1])}
            >
                <summary>
                    <h3>{label}</h3>
                    <span>
                        <FaChevronUp />
                    </span>
                </summary>
                <div>
                    <div>
                        <InputField
                            name={names[0]}
                            value={languagePack[names[0]]}
                            maxLength="64"
                            inputWrapperClass="inputWrapperLarge"
                            onChange={onInputChange}
                            label="title"
                            labelSize="small"
                            disabled={!names[0]}
                            error={errors[names[0]]}
                        />
                    </div>
                    <div>
                        <EditorInputField
                            label="content"
                            editorClass="editor"
                            name={names[1]}
                            testingId={names[1]}
                            initialValue={languagePack[names[1]]}
                            language={language}
                            error={errors[names[1]]}
                            onChange={onInputChange}
                            toolbarOptions={toolbarOptions}
                        />
                    </div>
                </div>
            </details>
        ))}

        <details onToggle={onToggleDetails} data-id="standardFields">
            <summary>
                <h3>Standard fields</h3>
                <span>
                    <FaChevronUp />
                </span>
            </summary>
            <div>
                <div>
                    {standardFields.slice(0, 5).map(({ name, label }) => (
                        <InputField
                            key={name}
                            name={name}
                            value={languagePack[name]}
                            maxLength="64"
                            inputWrapperClass="inputWrapperLarge"
                            onChange={onInputChange}
                            label={label}
                            error={errors[name]}
                        />
                    ))}
                </div>
                <div>
                    {standardFields.slice(5).map(({ name, label }) => (
                        <InputField
                            key={name}
                            name={name}
                            value={languagePack[name]}
                            maxLength="64"
                            inputWrapperClass="inputWrapperLarge"
                            onChange={onInputChange}
                            label={label}
                            error={errors[name]}
                        />
                    ))}
                </div>
            </div>
        </details>
    </section>
);

const toolbarOptions = {
    options: ['inline'],
    inline: {
        options: ['bold'],
        bold: { className: 'noBorder' },
    },
};

export default Configuration;

import { Link } from 'react-router-dom';
import {
    FiDownload as DownloadIcon,
    FiEdit2 as EditIcon,
    FiCopy as CopyIcon,
    FiTrash2 as TrashIcon,
    FiLoader as SpinnerIcon,
} from 'react-icons/fi';
import { BsBarChart as BarChartIcon } from 'react-icons/bs';
import { LinkCell } from '..';
import IconButton from '../IconButton/IconButton';
import { ReactComponent as ArchiveIcon } from '../../assets/svg/archive.svg';
import { ReactComponent as TrashRestoreIcon } from '../../assets/svg/trash_restore.svg';

const ActionsCell = ({
    itemId,
    onExport,
    link,
    stats,
    edit,
    onCopy,
    onRestore,
    onArchive,
    onDelete,
    deleteDisabled,
    isExporting,
}) => (
    <div className="inline-flex text-right">
        {link && <LinkCell link={link} />}
        {onExport && (
            <span title="CSV Exports">
                <IconButton disabled={isExporting} onClick={() => onExport(itemId)}>
                    {isExporting ? <SpinnerIcon /> : <DownloadIcon size="20" />}
                </IconButton>
            </span>
        )}
        {stats && (
            <span>
                <IconButton>
                    <BarChartIcon size="20" />
                </IconButton>
            </span>
        )}
        {edit && (
            <span title="Edit">
                <IconButton as={Link} to={`/projects/${itemId}`}>
                    <EditIcon size="20" />
                </IconButton>
            </span>
        )}
        {onCopy && (
            <span title="Copy">
                <IconButton onClick={() => onCopy(itemId)}>
                    <CopyIcon size="20" />
                </IconButton>
            </span>
        )}
        {onArchive && (
            <span>
                <IconButton onClick={() => onArchive(itemId)}>
                    <ArchiveIcon />
                </IconButton>
            </span>
        )}
        {onRestore && (
            <span>
                <IconButton onClick={() => onRestore(itemId)}>
                    <TrashRestoreIcon />
                </IconButton>
            </span>
        )}
        {onDelete && (
            <span title={deleteDisabled ? 'Locked' : 'Delete'}>
                <IconButton onClick={() => onDelete(itemId)} disabled={deleteDisabled}>
                    <TrashIcon size="20" color="var(--brand)" />
                </IconButton>
            </span>
        )}
    </div>
);

export default ActionsCell;

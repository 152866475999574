import { ImSpinner2 } from 'react-icons/im';
import style from './Spinner.module.scss';

const Spinner = ({ fullscreen }) => (
    <div className={`${style.spinner} spinner ${fullscreen ? style.fullscreen : ''}`}>
        <ImSpinner2 />
    </div>
);

export default Spinner;

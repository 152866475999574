const getHighestTitleNumber = (list, baseName) =>
    list.reduce((highestNumber, currentTitle) => {
        const regex = new RegExp(`.*${baseName}.*\\d+$`, 'gi');
        const normalizedTitle = currentTitle.match(regex)?.pop();
        if (!normalizedTitle) return highestNumber;

        const titleNumber = +normalizedTitle.match(/\d+$/).pop();
        if (titleNumber && titleNumber > highestNumber) highestNumber = titleNumber;
        return highestNumber;
    }, 0);

export default getHighestTitleNumber;

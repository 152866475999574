import { useState, useRef, useLayoutEffect, useEffect } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw, ContentState, EditorState, RichUtils, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { getTextFromHtmlString } from '../../utils';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'draft-js/dist/Draft.css';

import InputErrorMessage from '../InputErrorMessage/InputErrorMessage';
import style from './EditorInputField.module.scss';

const MAX_LENGTHS = {
    questText: 192,
    refreshText: 192,
    arrowText: 192,
    endText: 192,
    popupText: 256,
    completeText: 256, // (ostaju tagovi, ne radi underline, ni bold)
    participatingText: 256,
    description: 2048,
    secondDescription: 2048,
    reminder: 2048,
};

const createStateFromInitialValue = (initialValue) =>
    EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(initialValue || '')),
    );

const EditorInputField = ({
    label = '',
    labelSize = '',
    testingId = '',
    editorClass = '',
    className = '',
    editorMinHeight = '8rem',
    editorWidth = '24rem',
    toolbarOptions = defaultToolbarOptions,
    error,
    name,
    disabled,
    isDirty,
    isTouched,
    onEnter,
    onChange,
    initialValue = '',
    language,
    onPaste,
    ...props
}) => {
    // const createStateFromString = (value) =>
    //     EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(value || '')));

    const MAX_LENGTH = MAX_LENGTHS[name] ?? 256;
    const [editorState, setEditorState] = useState(createStateFromInitialValue(initialValue));
    const editorRef = useRef();
    const enterPressed = useRef(false);

    useEffect(() => {
        if (!name) return;
        setEditorState(createStateFromInitialValue(initialValue));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name, language]);

    const getLengthOfSelectedText = () => {
        const currentSelection = editorState.getSelection();
        const isCollapsed = currentSelection.isCollapsed();

        let length = 0;

        if (!isCollapsed) {
            const currentContent = editorState.getCurrentContent();
            const startKey = currentSelection.getStartKey();
            const endKey = currentSelection.getEndKey();
            const startBlock = currentContent.getBlockForKey(startKey);
            const isStartAndEndBlockAreTheSame = startKey === endKey;
            const startBlockTextLength = startBlock.getLength();
            const startSelectedTextLength =
                startBlockTextLength - currentSelection.getStartOffset();
            const endSelectedTextLength = currentSelection.getEndOffset();
            const keyAfterEnd = currentContent.getKeyAfter(endKey);

            if (isStartAndEndBlockAreTheSame) {
                length += currentSelection.getEndOffset() - currentSelection.getStartOffset();
            } else {
                let currentKey = startKey;

                while (currentKey && currentKey !== keyAfterEnd) {
                    if (currentKey === startKey) {
                        length += startSelectedTextLength + 1;
                    } else if (currentKey === endKey) {
                        length += endSelectedTextLength;
                    } else {
                        length += currentContent.getBlockForKey(currentKey).getLength() + 1;
                    }

                    currentKey = currentContent.getKeyAfter(currentKey);
                }
            }
        }

        return length;
    };

    const handleBeforeInput = () => {
        const currentContent = editorState.getCurrentContent();
        const currentContentLength = currentContent.getPlainText().length;
        const selectedTextLength = getLengthOfSelectedText();

        if (currentContentLength - selectedTextLength > MAX_LENGTH - 1) {
            return 'handled';
        }
        return 'not-handled';
    };

    const handlePastedText = (pastedText) => {
        const currentContent = editorState.getCurrentContent();
        const currentContentLength = currentContent.getPlainText().length;
        const selectedTextLength = getLengthOfSelectedText();

        if (currentContentLength + pastedText.length - selectedTextLength > MAX_LENGTH) {
            return 'handled';
        }
        return 'not-handled';
    };

    const handleEditorStateChange = (state) => {
        const htmlString = draftToHtml(convertToRaw(state.getCurrentContent()));
        if (
            htmlString !== initialValue &&
            !(getTextFromHtmlString(htmlString) === '' && initialValue === '')
        ) {
            onChange({ target: { name, value: htmlString } });
        }
        setEditorState(state);
    };

    const handleReturn = () => {
        enterPressed.current = true;
    };

    const handleChange = () => {
        if (enterPressed.current === true) {
            enterPressed.current = false;
            const nextState = [...editorState.getCurrentInlineStyle().values()].reduce(
                (state, inlineStyle) => RichUtils.toggleInlineStyle(state, inlineStyle),
                editorState,
            );

            setEditorState(nextState);
        }
    };

    useLayoutEffect(() => {
        const editorText = editorState?.getCurrentContent()?.getPlainText();
        if (editorRef.current && editorText === '') {
            editorRef.current.focusEditor();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const stateUpdatedRef = useRef(false);
    useEffect(() => {
        const htmlString = draftToHtml(convertToRaw(editorState.getCurrentContent()));

        if (getTextFromHtmlString(htmlString) === getTextFromHtmlString(initialValue)) return;
        const nextState = createStateFromInitialValue(initialValue);

        if (stateUpdatedRef.current) {
            stateUpdatedRef.current = false;
        } else {
            setEditorState(nextState);
            // safety check if bad values
            stateUpdatedRef.current = true;
        }
    }, [initialValue, editorState]);

    return (
        <div className={`${style.inputFieldEditorWrapper}`}>
            <div
                className={`${className} ${style.inputField} ${
                    disabled ? style.inputFieldDisabled : ''
                }`}
            >
                {label && (
                    <span
                        className={`inputFieldLabel ${
                            labelSize === 'small' ? 'inputFieldLabelSmall' : ''
                        }`}
                    >
                        <span>{label}</span>
                        {props.required && <span>*</span>}
                    </span>
                )}
                <div className={editorClass} data-testing-id={`editor-field-${testingId}`}>
                    <Editor
                        toolbarOnFocus
                        ref={editorRef}
                        toolbar={toolbarOptions}
                        editorState={editorState}
                        toolbarClassName={style.toolbarClassName}
                        wrapperClassName={style.wrapperClassName}
                        editorClassName={style.editorClassName}
                        editorStyle={{ minHeight: editorMinHeight, width: editorWidth }}
                        onEditorStateChange={handleEditorStateChange}
                        onChange={handleChange}
                        handleReturn={handleReturn}
                        handleBeforeInput={handleBeforeInput}
                        handlePastedText={handlePastedText}
                    />
                </div>
                <InputErrorMessage message={error?.message} />
            </div>
        </div>
    );
};

const defaultToolbarOptions = {
    options: ['inline', 'list', 'blockType'],
    inline: {
        inDropdown: false,
        options: ['bold'],
        bold: { className: style.noBorder },
    },
    list: {
        options: ['unordered'],
        unordered: { className: style.noBorder },
    },
    blockType: {
        inDropdown: false,
        options: ['Normal', 'H1'],
    },
};

export default EditorInputField;

import {
    createContext,
    useState,
    useContext,
    useCallback,
    useMemo,
    useRef,
    useEffect,
} from 'react';
import { FaCopy as CopyIcon } from 'react-icons/fa';
import { ImNewTab as NewTabIcon } from 'react-icons/im';
import { encodeGetParams } from '../../utils';
import style from './PreviewLink.module.scss';

const PreviewLinkContext = createContext(null);

function usePreviewLink() {
    const context = useContext(PreviewLinkContext);
    if (context == null) {
        throw new Error('usePreviewLink must be used within PreviewLinkContext');
    }
    return context;
}

function PreviewLinkProvider({ children }) {
    const [previewLink, setPreviewLink] = useState(null);

    const openPreviewLink = useCallback(
        (e, link) => {
            if (!link) {
                return;
            }

            //! Won't work without this timeout
            setTimeout(() => {
                setPreviewLink({
                    link,
                    x: e.clientX,
                    y: e.clientY,
                });
            }, 0);
        },
        [setPreviewLink],
    );

    const hidePreviewLink = useCallback(() => {
        setPreviewLink(null);
    }, []);

    const value = useMemo(
        () => ({ previewLink, hidePreviewLink, openPreviewLink }),
        [previewLink, hidePreviewLink, openPreviewLink],
    );

    return <PreviewLinkContext.Provider value={value}>{children}</PreviewLinkContext.Provider>;
}

function PreviewLink({ link, x, y }) {
    const [linkWtSearchParams, searchParams] = link.split('?');
    const encodedSearchParams = encodeGetParams(searchParams);
    const encodedLink = `${linkWtSearchParams}?${encodedSearchParams}`;

    const linkRef = useRef(null);

    useEffect(() => {
        if (linkRef.current) {
            linkRef.current.select();
            copyLink();
        }
    });

    const copyLink = () => {
        if (linkRef.current) {
            linkRef.current.select();
        }
        document.execCommand('copy');
    };

    return (
        <div
            className={style.previewLink}
            style={{ top: `${y - 70}px`, left: `${x - 600}px` }}
            onClick={(e) => e.stopPropagation()}
        >
            <input type="text" defaultValue={encodedLink} ref={linkRef} id="link" />
            <span title="Copy Link">
                <i onClick={copyLink}>
                    <CopyIcon />
                </i>
            </span>

            <span title="Open Link">
                <a href={encodedLink} target="_blank" rel="noreferrer">
                    <NewTabIcon />
                </a>
            </span>
        </div>
    );
}

export { PreviewLinkProvider, PreviewLink, usePreviewLink };

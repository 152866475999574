/* eslint-disable no-shadow */
import { useRef, Suspense } from 'react';
import { createPortal } from 'react-dom';
import { animated, config, useTransition } from 'react-spring';
import { MdClose } from 'react-icons/md';
import { useDiscard } from '../../hooks';
import style from './Modal.module.scss';
import { Spinner } from '..';

const Modal = ({ children = null, onClose = () => {}, visible, title = '' }) => {
    const ref = useRef();

    useDiscard(ref, onClose);

    const transitions = useTransition(visible, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        reverse: visible,
        config: config.stiff,
    });

    // https://reactjs.org/docs/portals.html
    return createPortal(
        transitions(
            (styleProps, visible) =>
                visible && (
                    <animated.div style={styleProps}>
                        <div className={style.backdrop} onClick={onClose} />
                        <dialog className={style.modal} open>
                            <Suspense fallback={<Spinner />}>
                                <header>
                                    <button type="button">
                                        <MdClose onClick={onClose} color="#c4c4c4" />
                                    </button>
                                    <h2>{title}</h2>
                                </header>
                                <section>{children}</section>
                            </Suspense>
                        </dialog>
                    </animated.div>
                ),
        ),
        document.getElementById('modal'),
    );
};

export default Modal;

import {
    FiChevronLeft as ChevronLeftIcon,
    FiChevronRight as ChevronRightIcon,
    FiChevronsLeft as ChevronsLeftIcon,
    FiChevronsRight as ChevronsRightIcon,
} from 'react-icons/fi';
import { IconButton } from '..';
import style from './Pagination.module.scss';

const Pagination = ({
    state,
    setPageSize,
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    pageCount,
}) => {
    const allPageNumbers = Array.from({ length: pageCount }, (el, idx) => idx + 1);
    const pageNumbers = makeVisiblePageNums(state.pageIndex + 1, allPageNumbers, pageCount);

    return (
        <div className={style.pagination}>
            <label>
                <span>Show:</span>
                <select
                    value={state.pageSize}
                    onChange={(e) => {
                        const { value } = e.target;
                        setPageSize(value);
                    }}
                >
                    <option value={10}>10</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                </select>
            </label>
            <div>
                <IconButton onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    <ChevronsLeftIcon />
                </IconButton>
                <IconButton onClick={previousPage} disabled={!canPreviousPage}>
                    <ChevronLeftIcon />
                </IconButton>
                <div className={style.paginationNumbers}>
                    {pageNumbers.map((num) => (
                        <span
                            className={`${state.pageIndex + 1 === num ? 'active' : ''}`}
                            onClick={() => gotoPage(num - 1)}
                            key={num}
                        >
                            {num}
                        </span>
                    ))}
                </div>

                <IconButton onClick={nextPage} disabled={!canNextPage}>
                    <ChevronRightIcon />
                </IconButton>
                <IconButton onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    <ChevronsRightIcon />
                </IconButton>
            </div>
            <label>
                <span>Go to page:</span>
                <select
                    value={state.pageIndex + 1}
                    onChange={(e) => {
                        const { value } = e.target;
                        gotoPage(value - 1);
                    }}
                >
                    {allPageNumbers.map((pageNum) => (
                        <option key={pageNum} value={pageNum}>
                            {pageNum}
                        </option>
                    ))}
                </select>
            </label>
        </div>
    );
};

const makeVisiblePageNums = (page, pageNumbers, totalPages) => {
    if (page < 5) return pageNumbers.slice(0, 5);
    if (page === totalPages) return pageNumbers.slice(page - 5);
    return pageNumbers.slice(page - 4, page + 2);
};

export default Pagination;

import { useEffect, useState, useCallback, useMemo } from 'react';
import { useParams, useNavigate, useOutletContext } from 'react-router-dom';
import {
    FiSave as SaveIcon,
    FiPlus as PlusIcon,
    FiChevronsLeft as ChevronsLeftIcon,
    FiChevronsRight as ChevronsRightIcon,
} from 'react-icons/fi';
import { FaChevronUp } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Button, InputField, Select, NavTabs, Switch, Checkbox } from '../../components';
import useAppState from '../../state/useAppState';
import { getHighestTitleNumber, makeId, languageOptions } from '../../utils';
import Configuration from './Configuration';
import Task from './Task';
import { taskModals, languageFieldNames } from '../constants';
// eslint-disable-next-line css-modules/no-unused-class
import style from './CellPage.module.scss';

const CellPage = () => {
    const { languages, user } = useAppState();
    const params = useParams();
    const navigate = useNavigate();
    const [language, setLanguage] = useState('en');
    const [isLangDefault, setIsLangDefault] = useState(false);
    const [
        {
            errors,
            setErrors,
            validateTask,
            project,
            onUpdate,
            onSave,
            isSaving,
            setActiveModal,
            setDuplicateName,
        },
    ] = useOutletContext();
    const [expandAll, setExpandAll] = useState(false);

    const cell = useMemo(
        () => project?.cells?.find((innerCell) => String(innerCell.order) === params.cellId),
        [project.cells, params.cellId],
    );
    const cellId = cell?.id || null;
    const tasks = useMemo(() => cell?.tasks || [], [cell]);
    const taskNames = useMemo(() => tasks?.map((innerTask) => innerTask.taskTitle), [tasks]);

    const getLangConfig = (langCode) =>
        languages?.find((lang) => lang.code === langCode && langCode.cellId === cellId) ||
        languages?.find((lang) => lang.code === langCode);

    const handleSetCell = useCallback(
        (name, value) => {
            let prop = name;
            if (name === 'selectedLanguage') {
                prop = 'language';
                setLanguage(value);
                setIsLangDefault(false);
                const langConfig = getLangConfig(value);
                value = { ...langConfig, cellId: cell.id };
            }
            if (name === 'redirect' && value) {
                value = value.replace('http://', '').replace('https://', '');
            }
            const newCells = project.cells.map((innerCell) => {
                if (innerCell.order !== cell.order) return innerCell;
                return { ...cell, [prop]: value };
            });
            const updateData = { cells: newCells };
            if (isLangDefault && name === 'language') {
                updateData.isLangDefault = true;
                updateData.langDefault = value;
            }
            onUpdate(updateData);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [cell, languages, onUpdate, project.cells],
    );

    const handleDefaultLanguage = () => {
        setIsLangDefault((p) => !p);
    };

    useEffect(() => {
        onUpdate({ isLangDefault, langDefault: cell?.language });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLangDefault]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        const { cells } = project;
        let message = '';
        if (name === 'name') {
            if (cells?.find((innerCell) => innerCell.name === value))
                message = 'Cell with that name already exists';
        }
        if (!value) {
            message = 'This field is required.';
        }
        if (message) {
            setErrors((prev) => ({
                ...prev,
                [name]: { message },
            }));
        } else {
            const newErrors = { ...errors };
            delete newErrors[name];
            setErrors(newErrors);
        }
        if (languageFieldNames.includes(name)) {
            handleSetCell('language', { ...cell.language, [name]: value });
        } else {
            handleSetCell(name, value);
        }
    };

    const getNextCell = (dir = 'forward') =>
        dir === 'forward'
            ? project.cells.find((innerCell) => innerCell.order > cell.order)
            : project.cells.findLast((innerCell) => innerCell.order < cell.order);

    const handleCellNav = (dir = 'forward') => {
        const nextCell = getNextCell(dir);
        if (nextCell) navigate(`/projects/${params.projectId}/${nextCell.order}`);
    };

    const getClassForCellNav = (dir = 'forward') => {
        if (!project?.cells) return style.cellPageHeaderNavDisabled;

        const nextCell = getNextCell(dir);

        if (nextCell) return style.cellPageHeaderNavActive;
        return style.cellPageHeaderNavDisabled;
    };

    const handleToggleDetails = () => {
        const detailsOpenStatus = [...document.querySelectorAll('section > details')].map(
            (element) => element.open,
        );
        const everyDetailOpened = detailsOpenStatus.every((isOpened) => isOpened);
        const everyDetailClosed = detailsOpenStatus.every((isOpened) => !isOpened);
        if (everyDetailOpened) {
            setExpandAll(true);
        } else if (everyDetailClosed) {
            setExpandAll(false);
        }
    };

    const createTask = (task, taskTitle) => {
        const newTask = task
            ? { ...task }
            : {
                  taskType: '',
                  package: '',
                  url: '',
                  timeToComplete: 600,
                  description: '',
                  secondDescription: '',
                  screen: true,
                  camera: false,
                  screenGrabQty: 2,
                  cameraGrabQty: 2,
                  calibration: true,
                  reminder: '',
              };
        delete newTask.id;
        newTask.orderId = makeId();

        const highestTitleNumber = getHighestTitleNumber(taskNames, 'Task');
        newTask.taskTitle = taskTitle || `Task ${highestTitleNumber + 1}`;

        const taskWithSameName = tasks?.find(
            (innerTask) =>
                innerTask.orderId !== newTask.orderId && innerTask.taskTitle === newTask.taskTitle,
        );
        if (taskWithSameName) {
            newTask.taskTitle += `_${makeId().slice(0, 4)}`;
        }

        const newTasks = [...tasks, newTask];
        handleSetCell('tasks', newTasks);
        if (taskTitle) {
            toast(`Task "${newTask.taskTitle}" duplicated.`);
        }
    };

    const handleTaskChange = (event, task) => {
        const { name, value } = event.target;
        const newTask = { ...task, [name]: value };
        if (name === 'camera') {
            newTask.calibration = value;
        }

        const newTasks = tasks.map((innerTask) => {
            if (innerTask !== task) return innerTask;
            return newTask;
        });

        if (errors?.tasks?.[task.orderId]) {
            const taskErrors = validateTask(newTask);
            const newErrors = { ...errors };
            if (taskErrors && Object.keys(taskErrors).length && !errors.disabled) {
                newErrors.tasks = { ...errors.tasks, ...taskErrors };
            } else if (newErrors.tasks) {
                delete newErrors.tasks[task.orderId];
            }
            if (newErrors.tasks && !Object.keys(newErrors.tasks).length) delete newErrors.tasks;
            setErrors(newErrors);
        }

        handleSetCell('tasks', newTasks);
    };

    const handleDeleteTask = (task) => {
        const newTasks = tasks.filter((innerTask) => innerTask !== task);
        handleSetCell('tasks', newTasks);

        if (errors?.tasks) {
            const newErrors = { ...errors };
            delete newErrors.tasks[task.orderId];
            setErrors(newErrors);
        }
        toast(`Task "${task.taskTitle}" deleted.`);
    };

    const handleDuplicateTaskModal = (task) => {
        const modal = { ...taskModals.duplicate };
        modal.text = `${modal.text} "${task.taskTitle}"?`;
        const highestTitleNumber = getHighestTitleNumber(taskNames, 'Task');
        setDuplicateName(`Task ${highestTitleNumber + 1}`);
        modal.handle = (taskTitle) => createTask(task, taskTitle);
        setActiveModal(modal);
    };

    const handleDeleteTaskModal = (task) => {
        const modal = { ...taskModals.delete };
        modal.text = `${modal.text} "${task.taskTitle}"?`;
        modal.handle = () => handleDeleteTask(task);
        setActiveModal(modal);
    };

    const moveTask = useCallback(
        (dragIndex, hoverIndex) => {
            const dragTask = tasks.find((_, idx) => idx === dragIndex);
            const newTasks = tasks.slice();
            newTasks.splice(dragIndex, 1);
            newTasks.splice(hoverIndex, 0, dragTask);
            handleSetCell('tasks', newTasks);
        },
        [tasks, handleSetCell],
    );

    useEffect(() => {
        [...document.querySelectorAll('section > details')].forEach((element) => {
            element.open = expandAll;
        });
    }, [expandAll]);

    useEffect(() => {
        if (cell?.language?.code) {
            setLanguage(cell.language.code);
        }
    }, [cell]);

    const links = [
        { text: 'Project overview', to: '/projects' },
        {
            text: params.projectId === 'new' ? 'Create new project' : `Project ${project?.name}`,
            to: `/projects/${params.projectId}`,
        },
        { text: cell?.name, to: `/projects/${params.projectId}/${params.cellId}` },
    ];

    const availableLanguageOptions = user.languages.map((langCode) => ({
        text: languageOptions.find((lang) => lang.code === langCode)?.native,
        value: langCode,
    }));

    const languagePack = cell?.language || getLangConfig(language);

    if (!cell) return null;

    return (
        <section className={style.cellPage}>
            <NavTabs path={links}>
                <div className="menuBarButtons">
                    <button
                        type="button"
                        onClick={onSave}
                        disabled={isSaving || Object.keys(errors).length > 1}
                    >
                        <SaveIcon />
                        Save
                    </button>
                </div>
            </NavTabs>
            <div className={style.cellPageMain}>
                <section>
                    <article className={style.cellPageHeader}>
                        <h2>{cell?.name}</h2>
                        <div className={style.cellPageHeaderNav}>
                            <div
                                className={getClassForCellNav('back')}
                                onClick={() => handleCellNav('back')}
                            >
                                <ChevronsLeftIcon />
                                <span>Previous cell</span>
                            </div>
                            <span>|</span>
                            <div
                                className={getClassForCellNav('forward')}
                                onClick={() => handleCellNav('forward')}
                            >
                                <span>Next cell</span>
                                <ChevronsRightIcon />
                            </div>
                        </div>
                    </article>
                    <article className={style.cellPageBody}>
                        <div>
                            <InputField
                                name="name"
                                label="Cell name*"
                                pattern="[^\w+$]"
                                maxLength="64"
                                value={cell.name}
                                inputWrapperClass="inputWrapperLarge"
                                onChange={handleInputChange}
                                error={errors.name}
                            />
                            <Select
                                search
                                label="Select language"
                                options={availableLanguageOptions}
                                name="selectedLanguage"
                                value={language}
                                onChange={handleSetCell}
                            />
                            <Checkbox
                                value={isLangDefault}
                                checked={isLangDefault}
                                onChange={handleDefaultLanguage}
                                name="defaultLanguage"
                                label="Use as a default for this language"
                            />
                        </div>
                        <div>
                            <InputField
                                name="redirect"
                                value={cell.redirect}
                                inputWrapperClass="inputWrapperLarge"
                                onChange={handleInputChange}
                                label="Redirect link*"
                                placeholder="www.example.com (without the http/https)"
                                error={errors.redirect}
                            />
                            <InputField
                                name="redirectTime"
                                type="number"
                                min="0"
                                value={cell.redirectTime}
                                inputWrapperClass="inputWrapperLarge"
                                onChange={handleInputChange}
                                label="Redirect time* (seconds)"
                                placeholder="Time in seconds"
                                error={errors.redirectTime}
                            />
                        </div>
                    </article>
                </section>
                <section>
                    <article className={style.cellPageExpandableWrapper}>
                        <details data-id="configuration">
                            <summary>
                                <h2>Configuration</h2>
                                <span>
                                    <FaChevronUp />
                                </span>
                            </summary>
                            <div className={style.cellPageExpandableWrapperBody}>
                                <div>
                                    <Switch
                                        onChange={() => {
                                            setExpandAll((prev) => !prev);
                                        }}
                                        value={expandAll}
                                        name="expandAll"
                                        type="checkbox"
                                        label="Expand all"
                                    />
                                </div>
                                <Configuration
                                    language={language}
                                    languagePack={languagePack}
                                    onToggleDetails={handleToggleDetails}
                                    onInputChange={handleInputChange}
                                    errors={errors}
                                />
                            </div>
                        </details>
                    </article>
                </section>
                <section>
                    <article className={style.cellPageExpandableWrapper}>
                        <details>
                            <summary>
                                <h2>Tasks</h2>
                                <span>
                                    <FaChevronUp />
                                </span>
                            </summary>
                            <div className={style.cellPageTaskMenu}>
                                {/* <div className="radioWrapper">
                                    <span>Task randomization:</span>
                                    <Radio name="randomization" label="Don't randomize" />
                                    <Radio name="randomization" label="Randomize one" />
                                    <Radio name="randomization" label="Randomize all" />
                                </div> */}
                                <div
                                    className={style.cellPageCreateTask}
                                    onClick={() => createTask()}
                                >
                                    <PlusIcon viewBox="0 0 16 24" />
                                    <span>Create Task</span>
                                </div>
                            </div>
                            <section className={style.tasksContainer}>
                                {tasks.map((task, index) => (
                                    <Task
                                        key={task.orderId}
                                        id={task.orderId}
                                        index={index}
                                        task={task}
                                        errors={!errors.disabled && errors.tasks?.[task.orderId]}
                                        onMove={moveTask}
                                        onChange={handleTaskChange}
                                        onCopy={handleDuplicateTaskModal}
                                        onDelete={handleDeleteTaskModal}
                                    />
                                ))}
                            </section>
                            <div className={style.createTaskBottom}>
                                <Button
                                    backgroundColor="var(--main_text_color)"
                                    borderColor="var(--main_text_color)"
                                    onClick={() => createTask()}
                                >
                                    <PlusIcon /> Create task
                                </Button>
                            </div>
                        </details>
                    </article>
                </section>
            </div>
        </section>
    );
};

export default CellPage;

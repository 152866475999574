import routes from './routes';
import INSTANCE, { get, post, httpDelete } from './config';
// import dummyData from './dummyData.json';

export const getLanguages = (cellId) =>
    get(`${routes.languages.cell}?cellId=${Number(cellId) || ''}`);

export const getProjects = (params) =>
    get(routes.projects, { params }).then(({ projects }) =>
        projects.map(({ createdAt, updatedAt, ...rest }) => ({
            ...rest,
            createdAt: new Date(createdAt),
            updatedAt: new Date(updatedAt),
        })),
    );

export const getProject = ({ queryKey: [, id] }) => id && get(`${routes.projects}/${id}`);

export const deleteProject = (id) => id && httpDelete(`${routes.projects}/${id}`);

export const getUser = (token = '') =>
    get(routes.user, {
        headers: { Authorization: `Bearer ${token}` },
    });

export const getUsers = () => get(routes.users);

export const saveProject = (data) => post(routes.projects, data);

export const logout = () => post(routes.logout);

export const getFile = (route, filename, options = {}) =>
    // eslint-disable-next-line import/no-named-as-default-member
    INSTANCE.get(route).then((res) => {
        const { headers } = res;
        let { data } = res;
        filename = filename || headers['content-disposition'].split(';')[1].split('=')[1];
        filename = filename.replace(/"/g, '');
        const type = headers['content-type'];
        if (type.includes('json')) {
            data = JSON.stringify(data, null, 2);
        }
        if (options.base64) {
            fetch(`data:${type};base64,${data}`)
                .then((r) => r.blob())
                .then((blob) => {
                    downloadBlob(filename, blob);
                });
        } else {
            const blob = new Blob([data], { type });
            downloadBlob(filename, blob);
        }
    });

const downloadBlob = (filename, blob) => {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
};

export const getCellStats = ({ queryKey: [, cellId] }) => get(`${routes.cell}/${cellId}/stats`);

export const getCellWebExport = ({ queryKey: [, cellId] }) =>
    get(`${routes.cell}/${cellId}/export`);

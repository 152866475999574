import { useState, useEffect, useMemo, useCallback } from 'react';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { useNavigate, useParams, useLocation, Outlet } from 'react-router-dom';
import { FiLoader as SpinnerIcon } from 'react-icons/fi';
import { getProject, getProjects, saveProject } from '../api';
import { useLanguages, useNavigationBlocker } from '../hooks';
import {
    getDataId,
    makeId,
    getHighestTitleNumber,
    getTextFromHtmlString,
    updateCellLink,
} from '../utils';
import { Modal, InputField, Button } from '../components';
import useAppState from '../state/useAppState';
import { cellModals, cellFields, configurationFieldNames, standardFieldNames } from './constants';

const Project = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { projectId, cellId } = useParams();
    const [destination, setDestination] = useState();
    const [project, setProject] = useState({});
    const [duplicateName, setDuplicateName] = useState('');
    const [duplicateCellId, setDuplicateCellId] = useState();
    const [activeModal, setActiveModal] = useState();
    const [errors, setErrors] = useState({ disabled: true });
    const [modalError, setModalError] = useState(null);

    const cell = useMemo(
        () => project?.cells?.find((innerCell) => String(innerCell.order) === cellId) || {},
        [project.cells, cellId],
    );
    useLanguages(cell.id);
    const { languages, projectNames, setProjectNames } = useAppState();
    const tasks = useMemo(() => cell?.tasks || [], [cell]);

    const { setBlockNavigation, continueNavigation } = useNavigationBlocker(
        ({ location: { pathname } }) => {
            if (cellId && pathname.includes(`projects/${projectId}/${cellId}`))
                continueNavigation(true);
            else setActiveModal(cellModals.save);
        },
    );

    const query = useQuery(['project', projectId], getProject, {
        enabled: Boolean(projectId && projectId !== 'new'),
        retry: 1,
        onError: () => {
            toast('An error has occurred, project is not loaded.');
        },
    });

    useQuery('projectOverview', getProjects, {
        retry: 1,
        onSuccess: (newData) => {
            setProjectNames(newData.map(({ name }) => name));
        },
        onError: () => {
            toast('An error has occurred, projects are not loaded.');
        },
    });

    const saveProjectMutation = useMutation(saveProject, {
        onError: () => {
            toast('An error has occurred, the project could not be saved.');
        },
        onSuccess: (data) => {
            if (typeof data === 'string') return;
            setActiveModal(null);
            toast('Progress saved!');
            setBlockNavigation(false);
            updateCellsLink(data.cells);
            setProject(data);
            if (activeModal?.action === 'save') {
                continueNavigation();
                return;
            }
            if (projectId === 'new') {
                setDestination(`${data.id}`);
            }
            setTaskIds(data);
            setErrors({ disabled: false });
        },
    });

    const updateCellsLink = useCallback((cells) => {
        cells?.forEach(updateCellLink);
    }, []);

    const handleUpdateProject = (newData) => {
        setProject((prev) => ({ ...prev, ...newData }));
        setBlockNavigation(true);
    };

    const handleSaveProject = () => {
        if (cellId) {
            const tasksErrors = tasks.reduce((memo, task) => {
                memo = { ...memo, ...validateTask(task) };
                return memo;
            }, {});
            Object.keys(tasksErrors).forEach((taskOrderId) => {
                openDetails(taskOrderId);
            });

            const requiredFields = Object.values(cellFields).flat();

            const cellErrors = requiredFields.reduce((err, field) => {
                const isLangField = cellFields.language.includes(field);
                if ((isLangField && !cell.language?.[field]) || (!isLangField && !cell[field])) {
                    err[field] = { message: 'This field is required.' };
                    if (configurationFieldNames.includes(field)) {
                        const detailsDataId = getDataId(field);
                        openDetails(detailsDataId);
                    }
                }
                return err;
            }, {});
            if (Object.keys(cellErrors).some((key) => configurationFieldNames.includes(key))) {
                openDetails('configuration');
            }
            if (Object.keys(cellErrors).some((key) => standardFieldNames.includes(key))) {
                openDetails('standardFields');
            }
            const newErrors = { ...cellErrors };
            if (Object.keys(tasksErrors).length) newErrors.tasks = tasksErrors;
            setErrors((prev) => ({ ...prev, ...newErrors }));
            if (Object.keys(newErrors).length) {
                toast.error('Progress not saved. Please fill in all required fields.', {
                    autoClose: 3000,
                });
                setErrors((prev) => ({ ...prev, disabled: false }));
                return;
            }
        }
        if (!saveProjectMutation.isLoading) {
            saveProjectMutation.mutate(project);
        }
    };

    const validateTask = (task) => {
        const { orderId } = task;
        const requiredFields = ['taskTitle', 'taskType', 'timeToComplete', 'description'];
        if (task.taskType === 'app') requiredFields.push('package');
        else if (task.taskType === 'web' || task.taskType === 'cms') requiredFields.push('url');

        const missingFields = requiredFields
            .filter((field) => {
                if (field === 'timeToComplete') {
                    const value = Number(task[field]);
                    if (!Number.isInteger(value) || value <= 0) return true;
                    return false;
                }
                return !getTextFromHtmlString(task[field]);
            })
            .reduce((memo, field) => {
                memo[field] = { message: 'This field is required.' };
                return memo;
            }, {});
        const taskErrors = missingFields;

        const taskWithSameName = tasks?.find(
            (innerTask) =>
                innerTask.orderId !== task.orderId && innerTask.taskTitle === task.taskTitle,
        );

        if (taskWithSameName) {
            taskErrors.taskTitle = { message: 'Task with that name already exists.' };
        }

        return Object.keys(taskErrors).length ? { [orderId]: taskErrors } : null;
    };

    const openDetails = (dataId) => {
        const details = document.querySelector(`details[data-id="${dataId}"]`);
        if (details) {
            details.open = true;
        }
    };

    const createCell = () => {
        const { cells } = project;
        const highestOrder = cells.reduce(
            (highest, innerCell) => (innerCell.order > highest ? innerCell.order : highest),
            0,
        );
        const newOrder = highestOrder + 1;

        const cellNames = cells.map((innerCell) => innerCell.name);
        const highestTitleNumber = getHighestTitleNumber(cellNames, 'Cell');

        let name = duplicateName;
        if (!name) {
            name = `Cell ${highestTitleNumber + 1}`;
            const projectName = project.name.toLowerCase().replace(/\s/g, '_');
            name = `${projectName}_cell_${highestTitleNumber + 1}`;
            const nameExists = cells?.find((innerCell) => innerCell.name === name);
            if (nameExists) name = `${projectName}_cell_${makeId()}`;
        }

        const cellToCopy =
            duplicateCellId && cells.find((innerCell) => innerCell.id === duplicateCellId);

        const language = cellToCopy?.language || languages?.find((lang) => lang.code === 'en');

        const newCell = {
            redirectTime: 1800,
            ...cellToCopy,
            name,
            language,
            id: undefined,
            order: newOrder,
            link: '',
            createdAt: new Date(),
            tasks: cellToCopy?.tasks
                ? cellToCopy.tasks.map((innerTask) => ({ ...innerTask, id: undefined }))
                : [],
        };
        const newCells = [...project.cells, newCell];
        saveProjectMutation.mutate({ ...project, cells: newCells });
        if (cellToCopy) {
            toast(`Cell "${name}" duplicated.`);
        }
    };

    const deleteCell = (cellData) => {
        const newCells = project.cells.filter((innerCell) => innerCell.order !== cellData.order);
        saveProjectMutation.mutate({ ...project, cells: newCells });
        toast(`Cell "${cellData.name}" deleted.`);
    };

    const cancelModalAction = () => {
        setActiveModal(null);
        setErrors({ disabled: true });
        query.refetch();
        if (activeModal?.action === 'save') {
            continueNavigation();
        }
    };

    const handleModalAction = (selectedAction) => {
        if (selectedAction === 'close') {
            setActiveModal(null);
            return;
        }
        const { action, handle } = activeModal;
        if (selectedAction === 'cancel') {
            cancelModalAction();
            return;
        }
        if (handle) {
            handle(duplicateName);
        } else if (action === 'duplicate') {
            createCell();
        } else if (action === 'save') {
            handleSaveProject();
        }
        setActiveModal(null);
    };

    const setTaskIds = (projectData) => {
        projectData.cells.forEach((innerCell) => {
            innerCell.tasks?.forEach((task) => {
                task.orderId = task.id;
            });
        });
    };

    useEffect(() => {
        if (query.data) {
            const projectData = {
                ...query.data,
                name: query.data.name || '',
                cells: query.data.cells || [],
            };
            setTaskIds(projectData);
            updateCellsLink(projectData.cells);
            setProject(projectData);
        }
    }, [query.data, updateCellsLink]);

    useEffect(() => {
        if (destination) {
            navigate(destination);
            setDestination('');
        }
    }, [navigate, destination]);

    useEffect(() => {
        let errorName = '';
        const taskWithSameName =
            cellId && tasks?.find((innerTask) => innerTask.taskTitle === duplicateName);
        const cellWithSameName =
            projectId &&
            !cellId &&
            project.cells?.find((innerCell) => innerCell.name === duplicateName);
        const projectWithSameName =
            !projectId && projectNames?.find((projectName) => projectName === duplicateName);
        if (taskWithSameName) errorName = 'Task';
        else if (cellWithSameName) errorName = 'Cell';
        else if (projectWithSameName) errorName = 'Project';

        if (errorName) {
            setModalError({ message: `${errorName} with that name already exists.` });
        } else {
            setModalError(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [duplicateName]);

    useEffect(() => {
        !cellId && query.refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const isSaving = saveProjectMutation.isLoading;

    useEffect(() => {
        if (activeModal === null) {
            setDuplicateCellId(null);
            setDuplicateName('');
        }
    }, [activeModal]);

    return (
        <div>
            {activeModal && (
                <Modal
                    visible={activeModal}
                    onClose={() => handleModalAction('close')}
                    title={activeModal.title}
                >
                    <div>
                        <p>{activeModal.text}</p>
                        {activeModal?.action === 'duplicate' && (
                            <InputField
                                value={duplicateName}
                                inputWrapperClass="inputWrapperLarge"
                                onChange={(e) => setDuplicateName(e.target.value)}
                                error={modalError}
                            />
                        )}
                        <div className="actionButtons">
                            {isSaving ? (
                                <SpinnerIcon />
                            ) : (
                                <>
                                    <Button
                                        onClick={() => handleModalAction(activeModal.action)}
                                        backgroundColor="white"
                                        color="var(--brand)"
                                        disabled={isSaving || modalError}
                                    >
                                        {activeModal.action}
                                    </Button>
                                    <Button onClick={() => handleModalAction('cancel')}>
                                        {activeModal.action === 'save' ? "Don't save" : 'Cancel'}
                                    </Button>
                                </>
                            )}
                        </div>
                    </div>
                </Modal>
            )}
            <Outlet
                context={[
                    {
                        project,
                        errors,
                        isSaving,
                        duplicateName,
                        setErrors,
                        validateTask,
                        createCell,
                        deleteCell,
                        setDuplicateCellId,
                        setDuplicateName,
                        setActiveModal,
                        onUpdate: handleUpdateProject,
                        onSet: setProject,
                        onModalAction: handleModalAction,
                        onSave: handleSaveProject,
                    },
                ]}
            />
        </div>
    );
};

export default Project;

import { useEffect, useState } from 'react';
import { Link, useSearchParams, useOutletContext } from 'react-router-dom';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import Qs from 'qs';
import { FiArchive as ArchiveIcon, FiPlus as PlusIcon } from 'react-icons/fi';
import { projectOverviewColumns } from './tableConfig';
import { getProjects, deleteProject, getFile, saveProject, getProject } from '../../api';
import { ActionCell, Table, Search } from '../../components';
import useAppState from '../../state/useAppState';
import { projectOverviewModals } from '../constants';
import { sortData, filterData } from '../../utils';
// eslint-disable-next-line css-modules/no-unused-class
import style from './ProjectOverviewPage.module.scss';

const ProjectOverviewPage = () => {
    const [urlParams, setUrlParams] = useSearchParams();
    const { setProjectNames } = useAppState();
    const [{ setActiveModal, setDuplicateName }] = useOutletContext();
    const [data, setData] = useState([]);
    const [isExporting, setIsExporting] = useState(false);

    const params = Qs.parse(urlParams.toString(), { arrayFormat: 'indices' });
    const { order = 'desc', sort = 'id' } = params;

    const query = useQuery('projectOverview', getProjects, {
        retry: 1,
        onSuccess: (newData) => {
            setProjectNames(newData.map(({ name }) => name));
        },
        onError: () => {
            toast('An error has occurred, projects are not loaded.');
        },
    });

    const handleExport = (itemId) => {
        if (isExporting) return;
        setIsExporting(true);
        getFile(`/projects/${itemId}/export`)
            .catch((err) => toast.error(err?.response?.data?.message || err?.message))
            .finally(() => setIsExporting(false));
    };

    const handleDuplicateProject = async (id, projectName) => {
        const project = await getProject({ queryKey: ['', id] });
        const newProject = { ...project };
        delete newProject.id;
        newProject.name = projectName;
        newProject.cells?.forEach((innerCell) => {
            delete innerCell.id;
            innerCell.tasks?.forEach((innerTask) => {
                delete innerTask.id;
            });
        });
        await saveProject(newProject);
        query.refetch();
        toast(`Project "${project.name}" duplicated.`);
    };

    const handleDuplicateProjectModal = (id) => {
        const project = query.data.find((proj) => proj.id === id);
        const modal = { ...projectOverviewModals.duplicate };
        modal.text = `${modal.text} "${project.name}"?`;
        setDuplicateName(`${project.name}_duplicate`);
        modal.handle = (projectName) => handleDuplicateProject(id, projectName);
        setActiveModal(modal);
    };

    const handleArchive = async (project) => {
        project.archived = true;
        await saveProject(project);
        query.refetch();
        toast(`Project "${project.name}" archived.`);
    };

    const handleArchiveProjectModal = async (id) => {
        const project = query.data.find((proj) => proj.id === id);
        const modal = { ...projectOverviewModals.archive };
        modal.text = `${modal.text} "${project.name}"?`;
        modal.handle = () => handleArchive(project);
        setActiveModal(modal);
    };

    const handleDelete = async (project) => {
        await deleteProject(project.id);
        query.refetch();
        toast(`Project "${project.name}" deleted.`);
    };

    const handleDeleteProjectModal = (id) => {
        const project = query.data.find((proj) => proj.id === id);
        const modal = { ...projectOverviewModals.delete };
        modal.text = `${modal.text} "${project.name}"?`;
        modal.handle = () => handleDelete(project);
        setActiveModal(modal);
    };

    const handleSort = (column) => {
        const newParams = { ...params };
        const { id } = column;
        const newSort = id;
        const prevOrder = urlParams.get('sort') === id && urlParams.get('order');

        let newOrder;
        if (!prevOrder) newOrder = 'asc';
        else if (prevOrder === 'asc') newOrder = 'desc';
        else if (prevOrder === 'desc') newOrder = '';

        newParams.order = newOrder;
        newParams.sort = newSort;
        if (!newOrder) {
            delete newParams.order;
            delete newParams.sort;
        }

        setUrlParams(Qs.stringify(newParams, { arrayFormat: 'indices' }));
    };

    useEffect(() => {
        if (!query.data) return;

        const dataToFilter = query.data.slice();

        const filteredData = filterData(dataToFilter, params.search);

        const sortedData = sortData(filteredData, sort, order);

        setData(sortedData);
    }, [query.data, params.search, order, sort]);

    const columns = projectOverviewColumns.map((col) => {
        if (col.id === 'exportAndStats')
            return {
                ...col,
                Cell: ({ row }) => (
                    <ActionCell
                        itemId={row.original.id}
                        onExport={handleExport}
                        isExporting={isExporting}
                    />
                ),
            };
        if (col.id === 'actions')
            return {
                ...col,
                Cell: ({ row }) => (
                    <ActionCell
                        itemId={row.original.id}
                        edit
                        onCopy={handleDuplicateProjectModal}
                        onArchive={handleArchiveProjectModal}
                        onDelete={handleDeleteProjectModal}
                    />
                ),
            };
        if (col.accessor === 'name' || col.accessor === 'id') {
            return {
                ...col,
                Cell: ({ row }) => (
                    <Link to={`/projects/${row.original.id}`}>{row.original[col.accessor]}</Link>
                ),
            };
        }
        if (col.accessor === 'createdAt') {
            return {
                ...col,
                Cell: ({ row }) => (
                    <span>{new Date(row.original[col.accessor]).toLocaleDateString()}</span>
                ),
            };
        }
        return col;
    });

    return (
        <section className={style.projectOverview}>
            <div className="menuBar">
                <h1>Project overview</h1>
                <div>
                    <Search />
                    <div className="menuBarActions">
                        <Link to="/projects/archives">
                            <ArchiveIcon />
                            <span>Archived</span>
                        </Link>
                        <Link to="/projects/new">
                            <PlusIcon viewBox="0 0 16 24" />
                            <span>Create Project</span>
                        </Link>
                    </div>
                </div>
            </div>
            <div className={style.projectOverviewTable}>
                <Table columns={columns} data={data} onSort={handleSort} />
            </div>
        </section>
    );
};

export default ProjectOverviewPage;

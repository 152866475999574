import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { FaChevronUp } from 'react-icons/fa';
import { FiMenu } from 'react-icons/fi';
import {
    InputField,
    Radio,
    Checkbox,
    Select,
    InputErrorMessage,
    EditorInputField,
} from '../../components';
import { ReactComponent as CopyIcon } from '../../assets/svg/copy.svg';
import { ReactComponent as TrashIcon } from '../../assets/svg/trash.svg';
import style from './Task.module.scss';

const Task = ({ errors = {}, id, index, task, onMove, onChange, onCopy, onDelete }) => {
    const ref = useRef(null);

    const [{ handlerId }, drop] = useDrop({
        accept: 'task',
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top - 20) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top - 20;
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            onMove(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'task',
        item: () => ({ id, index }),
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        canDrag: () => !ref.current?.open,
    });

    const opacity = isDragging ? 0 : 1;

    drag(drop(ref));

    const handleChange = (name, value) => {
        if (['calibration', 'screen', 'camera'].includes(name)) {
            value = !value;
        }
        onChange({ target: { name, value } }, task);
    };

    const handleEvent = (event) => {
        const { name, value } = event.target;
        handleChange(name, value);
    };

    const handleCopy = (event) => {
        event.preventDefault();
        onCopy(task);
    };

    const handleDelete = (event) => {
        event.preventDefault();
        onDelete(task);
    };

    const hasError = Object.keys(errors).length;

    return (
        <details
            ref={ref}
            style={{ opacity, border: hasError ? '1px solid red' : 'none' }}
            data-handler-id={handlerId}
            className={style.task}
            data-id={task.orderId}
        >
            <summary>
                <div>
                    <FiMenu />
                    <h3>{task.taskTitle}</h3>
                </div>
                <div>
                    <CopyIcon onClick={handleCopy} />
                    <TrashIcon onClick={handleDelete} />
                    <FaChevronUp />
                </div>
            </summary>
            <fieldset>
                <article>
                    <InputField
                        name="taskTitle"
                        label="Task title*"
                        pattern="[^\w+$]"
                        maxLength="50"
                        value={task.taskTitle}
                        inputWrapperClass="inputWrapperLarge"
                        onChange={handleEvent}
                        error={errors.taskTitle}
                    />
                    <div className="radioWrapper">
                        <span className={style.label}>Task type*</span>
                        <Radio
                            name="taskType"
                            label="App"
                            value={task.taskType === 'app'}
                            onChange={() => handleChange('taskType', 'app')}
                        />
                        <Radio
                            name="taskType"
                            label="Web"
                            value={task.taskType === 'web'}
                            onChange={() => handleChange('taskType', 'web')}
                        />
                        <Radio
                            name="taskType"
                            label="CMS"
                            value={task.taskType === 'cms'}
                            onChange={() => handleChange('taskType', 'cms')}
                        />
                        {errors.taskType && (
                            <InputErrorMessage
                                message={errors.taskType.message}
                                styles={{ top: '1.6rem', left: '10rem' }}
                            />
                        )}
                    </div>
                    {task.taskType === 'app' && (
                        <InputField
                            name="package"
                            label="Package*"
                            value={task.package}
                            placeholder="Enter package link"
                            inputWrapperClass="inputWrapperLarge"
                            onChange={handleEvent}
                            error={errors.package}
                        />
                    )}
                    {(task.taskType === 'web' || task.taskType === 'cms') && (
                        <InputField
                            name="url"
                            label="URL*"
                            value={task.url}
                            placeholder="Enter link"
                            inputWrapperClass="inputWrapperLarge"
                            onChange={handleEvent}
                            error={errors.url}
                        />
                    )}
                    <InputField
                        name="timeToComplete"
                        label="Time to complete* (seconds)"
                        value={task.timeToComplete}
                        maxLength={9}
                        min="0"
                        type="number"
                        placeholder="Time in seconds"
                        inputWrapperClass="inputWrapperLarge"
                        onChange={handleEvent}
                        error={errors.timeToComplete}
                    />
                    <div className="editorWrapper">
                        <EditorInputField
                            name="description"
                            label="Description text 1*"
                            className={style.editorWrapper}
                            editorClass="editor"
                            inputWrapperClass="inputWrapperLarge"
                            editorMinHeight="12rem"
                            editorWidth="28rem"
                            onChange={handleEvent}
                            error={errors.description}
                            initialValue={task.description}
                        />
                    </div>
                    <div className="editorWrapper">
                        <EditorInputField
                            name="secondDescription"
                            label="Description text 2"
                            className={style.editorWrapper}
                            editorClass="editor"
                            inputWrapperClass="inputWrapperLarge"
                            editorMinHeight="12rem"
                            editorWidth="28rem"
                            onChange={handleEvent}
                            error={errors.secondDescription}
                            initialValue={task.secondDescription}
                        />
                    </div>
                </article>
                <article>
                    <div>
                        <span className={style.label}>Recording services</span>
                        <Checkbox
                            onChange={handleChange}
                            value={task.screen}
                            checked={task.screen}
                            name="screen"
                            label="Screen"
                        />
                        <Checkbox
                            onChange={handleChange}
                            value={task.camera}
                            checked={task.camera}
                            name="camera"
                            label="Camera"
                        />
                    </div>
                    {task.screen && (
                        <Select
                            label="Screen grab quality"
                            options={qualityValues}
                            name="screenGrabQty"
                            value={task.screenGrabQty}
                            onChange={handleChange}
                        />
                    )}
                    {task.camera && (
                        <Select
                            label="Camera grab quality:"
                            options={qualityValues}
                            name="cameraGrabQty"
                            value={task.cameraGrabQty}
                            onChange={handleChange}
                        />
                    )}
                    {task.camera && (
                        <div>
                            <span className={style.label}>Face detection</span>
                            <Checkbox
                                onChange={handleChange}
                                value={task.calibration}
                                name="calibration"
                                checked={task.calibration}
                            />
                        </div>
                    )}
                    <div className="editorWrapper">
                        <EditorInputField
                            name="reminder"
                            label="Help text"
                            className={style.editorWrapper}
                            editorClass="editor"
                            inputWrapperClass="inputWrapperLarge"
                            onChange={handleEvent}
                            error={errors.reminder}
                            initialValue={task.reminder}
                        />
                    </div>
                </article>
            </fieldset>
        </details>
    );
};

const qualityValues = Object.freeze([
    { text: 'Low quality', value: 3 },
    { text: 'Standard quality', value: 2 },
    { text: 'High quality', value: 1 },
]);

export default Task;

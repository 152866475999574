import { MdOutlineErrorOutline } from 'react-icons/md';
import style from './ErrorPage.module.scss';

const ErrorPage = ({ error, resetErrorBoundary }) => {
    const handleReset = () => {
        resetErrorBoundary();
    };

    return (
        <section className={style.errorPage}>
            <h2>
                <MdOutlineErrorOutline />
                Something went wrong, here's some info about it:
            </h2>
            <p>
                {' '}
                {error?.name} <br />
                {error?.response?.data?.message || error?.message}
            </p>
            <span>
                Go <a onClick={handleReset}>home</a>, or back to{' '}
                <a href="https://eyesee-hub.com/">EyeSee Hub</a>
            </span>
        </section>
    );
};

export default ErrorPage;

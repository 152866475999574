import { useEffect } from 'react';

/**
 * Hook that alerts clicks outside of the passed ref
 */
export function useClickOutside(ref, cb) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                cb();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, cb]);
}

/**
 * Hook that listens for Escape key
 */
export function useEscape(cb) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (event.key === 'Escape') {
                cb();
            }
        }

        document.addEventListener('keyup', handleClickOutside);
        return () => {
            document.removeEventListener('keyup', handleClickOutside);
        };
    }, [cb]);
}

export function useDiscard(ref, cb) {
    useClickOutside(ref, cb);
    useEscape(cb);
}

const sortData = (dataToSort = [], sort = '', order = '') =>
    dataToSort.slice().sort((a, b) => {
        const aVal = a[sort] || '';
        const bVal = b[sort] || '';
        if (typeof aVal === 'string' && typeof bVal === 'string')
            return order === 'asc' ? aVal.localeCompare(bVal) : bVal.localeCompare(aVal);
        return order === 'asc' ? aVal - bVal : bVal - aVal;
    });

export default sortData;

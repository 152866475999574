export const cellWebExportColumns = [
    {
        accessor: 'cell',
        Header: 'cell',
    },
    {
        accessor: 'project',
        Header: 'project',
    },
    {
        accessor: 'row',
        Header: 'row',
    },
    {
        accessor: 'sguid',
        Header: 'sguid',
    },
    {
        accessor: 'task_title',
        Header: 'task_title',
    },
    {
        accessor: 'time_cum',
        Header: 'time_cum',
    },
    {
        accessor: 'time_step',
        Header: 'time_step',
    },
    {
        accessor: 'time',
        Header: 'time',
    },
    {
        accessor: 'step',
        Header: 'step',
    },
    {
        accessor: 'url',
        Header: 'url',
    },
    // {
    //     accessor: 'ACTION',
    //     Header: 'ACTION',
    // },
    // {
    //     accessor: 'WHERE_AM_I',
    //     Header: 'WHERE_AM_I',
    // },
    // {
    //     accessor: 'Search_Query_Text',
    //     Header: 'Search_Query_Text',
    // },
    // {
    //     accessor: 'Search_Query_Input',
    //     Header: 'Search_Query_Input',
    // },
    // {
    //     accessor: 'Search_Type',
    //     Header: 'Search_Type',
    // },
    // {
    //     accessor: 'Search_Order',
    //     Header: 'Search_Order',
    // },
    // {
    //     accessor: 'Menu_Umbrella',
    //     Header: 'Menu_Umbrella',
    // },
    // {
    //     accessor: 'Menu_Category',
    //     Header: 'Menu_Category',
    // },
    // {
    //     accessor: 'Menu_Subcategory',
    //     Header: 'Menu_Subcategory',
    // },
    // {
    //     accessor: 'Menu_PreviousItem',
    //     Header: 'Menu_PreviousItem',
    // },
    // {
    //     accessor: 'Filter_Sort_Category',
    //     Header: 'Filter_Sort_Category',
    // },
    // {
    //     accessor: 'Filter_Sort_Description',
    //     Header: 'Filter_Sort_Description',
    // },
    // {
    //     accessor: 'Category',
    //     Header: 'Category',
    // },
    // {
    //     accessor: 'Product_details',
    //     Header: 'Product_details',
    // },
    // {
    //     accessor: 'Product_name',
    //     Header: 'Product_name',
    // },
    // {
    //     accessor: 'Brand',
    //     Header: 'Brand',
    // },
    // {
    //     accessor: 'Quantity',
    //     Header: 'Quantity',
    // },
    // {
    //     accessor: 'Price',
    //     Header: 'Price',
    // },
    // {
    //     accessor: 'Other',
    //     Header: 'Other',
    // },
];

import { useEffect, useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import Qs from 'qs';
import { InputField } from '..';

const Search = () => {
    const [urlParams, setUrlParams] = useSearchParams();
    const [search, setSearch] = useState('');
    const urlParamIntervalId = useRef(null);

    const params = Qs.parse(urlParams.toString(), { arrayFormat: 'indices' });

    const handleSearch = (e) => {
        const { value } = e.target;
        setSearch(value);

        const newParams = { ...params, search: value };
        if (!value) {
            delete newParams.search;
        }

        clearInterval(urlParamIntervalId.current);
        urlParamIntervalId.current = setTimeout(() => {
            setUrlParams(Qs.stringify(newParams, { arrayFormat: 'indices' }));
        }, 200);
    };

    useEffect(() => {
        if (params.search) {
            setSearch(params.search);
        }
    }, [params.search]);

    return (
        <div>
            <InputField
                name="term"
                onChange={handleSearch}
                value={search}
                placeholder="Search"
                inputWrapperClass="inputWrapperLarge"
                isSearch
            />
        </div>
    );
};

export default Search;

import { useEffect } from 'react';
import { getUser } from '../api';
import useAppState from '../state/useAppState';

export const useAuthentication = () => {
    const { setUser } = useAppState();

    const clearURLParams = () => {
        const { location, history } = window;
        history.replaceState(
            null,
            '',
            location.pathname + location.search.replace(/[?&]t=[^&]+/, '').replace(/^&/, '?'),
        );
    };

    useEffect(() => {
        (async () => {
            const params = Object.fromEntries(new URLSearchParams(window.location.search));
            const { t } = params;

            const token = t || localStorage.getItem('mobileAdminToken');

            try {
                if (!token) throw new Error('Not authenticated.');
                const user = await getUser(token);

                localStorage.setItem('mobileAdminToken', token);
                setUser(user);
                clearURLParams();
            } catch (error) {
                console.error(error);
                window.location.replace('https://eyesee-hub.com/');
            }
        })();
    }, [setUser]);
};

import create from 'zustand';
import { devtools } from 'zustand/middleware';

const useAppState = create(
    devtools((set) => ({
        setUser: (user) => set({ user }),
        setProjectNames: (projectNames) => set({ projectNames }),
        setLanguages: (languages) => set({ languages }),
    })),
);

export default useAppState;

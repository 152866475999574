import { useState, useEffect, useRef } from 'react';
import { Link, useParams, useOutletContext, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
    FiSave as SaveIcon,
    FiEdit3 as PencilIcon,
    FiPlus as PlusIcon,
    FiDownload as DownloadIcon,
    FiLoader as SpinnerIcon,
} from 'react-icons/fi';
import { BsBarChart as BarChartIcon } from 'react-icons/bs';
import { ReactComponent as TableIcon } from '../../assets/svg/table.svg';
import { useClickOutside } from '../../hooks';
import { projectColumns } from './tableConfig';
import { NavTabs, Table, ActionCell, Button, IconButton } from '../../components';
import useAppState from '../../state/useAppState';
import { getHighestTitleNumber } from '../../utils';
import { cellModals } from '../constants';
import style from './ProjectPage.module.scss';
import { getFile } from '../../api';

const { REACT_APP_API_URL } = process.env;

const ProjectPage = () => {
    const location = useLocation();
    const { projectId } = useParams();
    const [isEditingProjectName, setIsEditingProjectName] = useState(false);
    const [editNameError, setEditNameError] = useState('');
    const [editName, setEditName] = useState('');
    const [activeDropdown, setActiveDropdown] = useState();
    const [isExporting, setIsExporting] = useState(false);
    const { projectNames } = useAppState();
    const editNameRef = useRef(null);
    const downloadLinksRef = useRef(null);
    const [
        {
            project,
            onSet,
            onUpdate,
            onSave,
            isSaving,
            createCell,
            deleteCell,
            setDuplicateCellId,
            setDuplicateName,
            setActiveModal,
        },
    ] = useOutletContext();

    const handleChangeName = (event) => {
        const { value } = event.target;
        setEditName(value);
        const regex = /^(\w|\s)+$/;
        if (!value) {
            setEditNameError('This field is required.');
        } else if (!value.match(regex)) {
            setEditNameError('Only alphanumeric characters and underscores are allowed.');
        } else if (projectNames?.includes(value.trim()) && value.trim() !== project?.name) {
            setEditNameError('That project name already exists.');
        } else {
            setEditNameError('');
        }
    };

    const updateName = () => {
        if (editNameError) return;
        setIsEditingProjectName((prev) => !prev);
        onUpdate({ name: editName.trim() });
    };

    const handleDuplicateCellModal = (cell) => {
        const modal = { ...cellModals.duplicate };
        modal.text = `${modal.text} "${cell.name}"?`;
        const cellNames = project.cells.map((innerCell) => innerCell.name);
        const highestTitleNumber = getHighestTitleNumber(cellNames, 'Cell');
        const projectName = project.name.toLowerCase().replace(/\s/g, '_');
        const cellName = `${projectName}_cell_${highestTitleNumber + 1}`;
        setDuplicateCellId(cell.id);
        setDuplicateName(cellName);
        setActiveModal(modal);
    };

    const handleDeleteCellModal = (cell) => {
        const modal = { ...cellModals.delete };
        modal.text = `${modal.text} "${cell.name}"?`;
        modal.handle = () => deleteCell(cell);
        setActiveModal(modal);
    };

    const handleExportCell = (cell) => {
        setActiveDropdown(cell.id);
    };

    const handleDownload = (url) => {
        if (isExporting) return;
        setIsExporting(true);
        getFile(url)
            .catch((err) => toast.error(err?.response?.data?.message || err?.message))
            .finally(() => setIsExporting(false));
    };

    useClickOutside(editNameRef, updateName);

    useClickOutside(downloadLinksRef, () => setActiveDropdown(null));

    useEffect(() => {
        if (project?.name) {
            setEditName(project.name);
        }
    }, [project]);

    useEffect(() => {
        if (location.pathname === '/projects/new') {
            const highestNumber = getHighestTitleNumber(projectNames, 'New project');
            const name = `New project ${highestNumber + 1}`;
            setEditName(name);
            onSet({ cells: [], name });
        }
    }, [projectNames, location, onSet]);

    const columns = projectColumns.map((col) => {
        if (col.id === 'name') {
            return {
                ...col,
                Cell: ({ row }) => (
                    <Link to={`/projects/${projectId}/${row.original.order}`}>
                        {row.original.name}
                    </Link>
                ),
            };
        }
        if (col.id === 'createdAt') {
            return {
                ...col,
                Cell: ({ row }) => (
                    <span>{new Date(row.original[col.id]).toLocaleDateString()}</span>
                ),
            };
        }
        if (col.id === 'statsAndExport') {
            return {
                ...col,
                Cell: ({ row }) => {
                    const cell = row.original;
                    return (
                        <div>
                            <span className="relative" title="CSV Exports">
                                <IconButton
                                    disabled={isExporting}
                                    onClick={() => handleExportCell(cell)}
                                >
                                    {isExporting ? <SpinnerIcon /> : <DownloadIcon size="20" />}
                                </IconButton>
                                {activeDropdown === cell.id && (
                                    <div className="downloadLinks" ref={downloadLinksRef}>
                                        <button
                                            type="button"
                                            onClick={() =>
                                                handleDownload(
                                                    `${REACT_APP_API_URL}/cell/${cell.id}/export/download`,
                                                )
                                            }
                                        >
                                            Download CSV
                                        </button>
                                        <a
                                            href={`${REACT_APP_API_URL}/cell/${cell.id}/results/download`}
                                        >
                                            Download files
                                        </a>
                                    </div>
                                )}
                            </span>
                            <span title="Web Export">
                                <IconButton as={Link} to={`${cell.order}/webexport`}>
                                    <TableIcon size="20" />
                                </IconButton>
                            </span>
                            <span title="Statistics">
                                <IconButton as={Link} to={`${cell.order}/stats`}>
                                    <BarChartIcon size="20" />
                                </IconButton>
                            </span>
                        </div>
                    );
                },
            };
        }
        if (col.id === 'actions')
            return {
                ...col,
                Cell: ({ row }) => {
                    const cell = row.original;
                    return (
                        <ActionCell
                            itemId={cell.order}
                            link={cell.link}
                            onCopy={() => handleDuplicateCellModal(cell)}
                            onDelete={() => handleDeleteCellModal(cell)}
                        />
                    );
                },
            };
        return {
            ...col,
            disableSortBy: true,
            Cell: ({ row }) => {
                const value = row.original[col.accessor];
                return value instanceof Date ? (
                    <span>{value.toLocaleDateString()}</span>
                ) : (
                    <span>{value}</span>
                );
            },
        };
    });

    const links = [
        { text: 'Project overview', to: '/projects' },
        {
            text: projectId === 'new' ? 'Create new project' : `Project ${project.name}`,
            to: `/projects/${projectId}`,
        },
    ];

    if (!project?.name) return null;

    return (
        <section className={style.projectPage}>
            <NavTabs path={links}>
                <div className="menuBarButtons">
                    <button type="button" onClick={onSave} disabled={isSaving || editNameError}>
                        <SaveIcon />
                        Save
                    </button>
                </div>
            </NavTabs>
            <article className={style.projectPageTable}>
                <div className={style.projectPageName}>
                    <div>Project name:</div>
                    <div>
                        {isEditingProjectName && (
                            <div>
                                <input
                                    ref={editNameRef}
                                    type="text"
                                    value={editName}
                                    pattern="[^\w+$]"
                                    maxLength="64"
                                    onChange={handleChangeName}
                                />
                                {editNameError && <span>{editNameError}</span>}
                            </div>
                        )}
                        {!isEditingProjectName && <h2>{project.name}</h2>}
                        <PencilIcon color="var(--brand)" size="1.125rem" onClick={updateName} />
                    </div>
                </div>
                <Table columns={columns} data={project.cells} isPaginated={false} />
                <div>
                    <Button
                        backgroundColor="var(--main_text_color)"
                        borderColor="var(--main_text_color)"
                        onClick={() => createCell()}
                    >
                        <PlusIcon /> Create cell
                    </Button>
                </div>
            </article>
        </section>
    );
};

export default ProjectPage;

export const cellModals = {
    save: {
        title: 'Save progress',
        text: 'Do you want to save progress before leaving?',
        action: 'save',
    },
    duplicate: {
        title: 'Duplicate cell',
        text: 'Are you sure you want to duplicate Cell',
        action: 'duplicate',
    },
    delete: {
        title: 'Delete cell',
        text: 'Are you sure you want to permanently delete cell',
        action: 'delete',
    },
};

export const taskModals = {
    duplicate: {
        title: 'Duplicate task',
        text: 'Are you sure you want to duplicate Task',
        action: 'duplicate',
    },
    delete: {
        title: 'Delete cell',
        text: 'Are you sure you want to permanently delete Task',
        action: 'delete',
    },
};

export const projectOverviewModals = {
    archive: {
        title: 'Archive project',
        text: 'Archive project',
        action: 'archive',
    },
    duplicate: {
        title: 'Duplicate project',
        text: 'Duplicate project',
        action: 'duplicate',
    },
    delete: {
        title: 'Delete project',
        text: 'Are you sure you want to delete project',
        action: 'delete',
    },
};

export const archivedProjectsModals = {
    restore: {
        title: 'Restore project',
        text: 'Restore project',
        action: 'restore',
    },
    delete: {
        title: 'Delete project',
        text: 'Are you sure you want to delete project',
        action: 'delete',
    },
};

export const configurationSections = [
    { names: ['questTitleText', 'questText'], label: 'instructions' },
    { names: ['refreshTitleText', 'refreshText'], label: 'refresh' },
    { names: ['arrowTitleText', 'arrowText'], label: 'next task' },
    { names: ['endTitleText', 'endText'], label: 'end task' },
    { names: ['popupTitleText', 'popupText'], label: 'permission pop-up' },
    { names: ['', 'participatingText'], label: 'participant' },
    { names: ['', 'completeText'], label: 'complete' },
];

export const standardFields = [
    { name: 'popupBtnText', label: 'Confirmation' },
    { name: 'uploadingFileText', label: 'Uploading file' },
    { name: 'ofText', label: 'ofText' },
    { name: 'progressText', label: 'Progress text' },
    { name: 'startTest', label: 'Start test' },
    { name: 'start', label: 'Start' },
    { name: 'continue', label: 'Continue' },
    { name: 'complete', label: 'Complete' },
    { name: 'completeStudy', label: 'Complete study' },
    { name: 'taskTxt', label: 'Task description' },
];

export const configurationFieldNames = configurationSections
    .map(({ names }) => names)
    .flat()
    .filter((val) => val);

export const standardFieldNames = standardFields.map(({ name }) => name);

export const languageFieldNames = configurationFieldNames.concat(standardFieldNames);

export const cellFields = {
    basic: ['name', 'redirect', 'redirectTime'],
    language: languageFieldNames,
};
